import React from 'react';

export default function Chevron({
  className,
  direction = 'left',
}: { className?: string; direction?: 'up' | 'right' | 'down' | 'left'; }): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="-4 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      transform={`rotate(${{
        up: 90,
        right: 180,
        down: 270,
        left: 0,
      }[direction]}, 0, 0)`}
      style={{ transition: 'transform 200ms ease' }}
    >
      <path d="M7.78 2.58039L6.6 1.40039L0 8.00039L6.6 14.6004L7.78 13.4204L2.36 8.00039L7.78 2.58039Z" />
    </svg>
  );
}
