import { TipListModel } from 'Models/TipListModel';
import { RootState } from 'Store';
import CitySelectors from './CitySelectors';
import UserSelectors from './UserSelectors';

function get(id: string): (state: RootState) => TipListModel | null {
  return (state: RootState) => {
    const tipList = state.tipLists[id] ?? null;

    if (!tipList) {
      return tipList;
    }

    return {
      ...tipList,
      city: CitySelectors.get(tipList.city.id)(state) ?? tipList.city,
      user: UserSelectors.get(tipList.user.id)(state) ?? tipList.user,
    };
  };
}

function list(...ids: string[]): (state: RootState) => TipListModel[] {
  return (state: RootState) => {
    const tipLists = ids.map((id) => state.tipLists[id]).filter((tipList) => tipList !== undefined);
    const cityIds: string[] = [];
    const userIds: string[] = [];

    tipLists.forEach((tipList) => {
      if (tipList.city?.id) {
        cityIds.push(tipList.city.id);
      }

      userIds.push(tipList.user.id);
    });

    const cities = CitySelectors.list(...cityIds)(state);
    const users = UserSelectors.list(...userIds)(state);

    return tipLists.map((tipList) => ({
      ...tipList,
      city: cities.find((city) => city.id === tipList.city?.id) ?? tipList.city,
      user: users.find((user) => user.id === tipList.user.id) ?? tipList.user,
    }));
  };
}

export default {
  get,
  list,
};
