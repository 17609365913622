import React from 'react';
import { TipModel } from 'Models/TipModel';
import Icons from 'Components/Icons';
import { ModalControllerContext, NotificationContext } from 'App';
import { useDispatch, useSelector } from 'react-redux';
import AuthSelectors from 'Store/Selectors/AuthSelectors';
import TipListActions from 'Store/Actions/TipListActions';
import TipListSelectors from 'Store/Selectors/TipListSelectors';
import UI from 'Components/UI';
import { TipListModel } from 'Models/TipListModel';
import { Link } from 'react-router-dom';
import RouteConfig from 'RouteConfig';
import MiscUtils from 'Utils/MiscUtils';
import css from './AddToTipListContextMenu.module.css';

type AddToTipListContextMenuProps = {
  tip: TipModel;
  buttonClassName?: string;
  align?: 'left' | 'right';
};

export default function AddToTipListContextMenu({
  tip,
  buttonClassName,
  align = 'right',
}: AddToTipListContextMenuProps): JSX.Element {
  const dispatch = useDispatch();
  const { signInDialog } = React.useContext(ModalControllerContext);
  const { tipListDialog } = React.useContext(ModalControllerContext);
  const auth = useSelector(AuthSelectors.get);
  const cityId = tip.venue?.cityId;
  const userId = auth?.id;
  const tipId = tip.id;
  const [relatedTipListIds, setRelatedTipListIds] = React.useState<string[]>([]);
  const relatedTipLists = useSelector(TipListSelectors.list(...relatedTipListIds));
  const [ohterTipListIds, setOtherTipListIds] = React.useState<string[]>([]);
  const otherTipLists = useSelector(TipListSelectors.list(...ohterTipListIds));
  const showNotification = React.useContext(NotificationContext);

  // Load all tip lists given query args.
  const loadAllTipLists = React.useCallback(
    async (args: Parameters<typeof TipListActions.list>[1]): Promise<TipListModel[]> => {
      const [nextTipLists] = await TipListActions.list(dispatch, args);

      if (nextTipLists.length < (args?.limit ?? 20)) {
        return [...nextTipLists];
      }

      return [
        ...nextTipLists,
        ...await loadAllTipLists({ ...args, offset: (args?.offset ?? 0) + 1 }),
      ];
    },
    [dispatch],
  );

  // Load all tip lists.
  const loadTipLists = React.useCallback(async () => {
    setRelatedTipListIds([]);
    setOtherTipListIds([]);

    if (!userId) {
      return;
    }

    loadAllTipLists({
      limit: 100,
      offset: 0,
      filters: { userIds: [userId], '!tipId': tipId, cityIds: cityId ? [cityId] : undefined },
    }).then((nextTipLists) => setRelatedTipListIds(nextTipLists.map((tipList) => tipList.id)));

    loadAllTipLists({
      limit: 100,
      offset: 0,
      filters: { userIds: [userId], '!tipId': tipId, '!cityIds': cityId ? [cityId] : undefined },
    }).then((nextTipLists) => setOtherTipListIds(nextTipLists.map((tipList) => tipList.id)));
  }, [loadAllTipLists, cityId, userId, tipId]);

  const addTip = React.useCallback(async (tipList: TipListModel) => {
    await TipListActions.addTips(dispatch, tipList, [tip], auth?.id ?? '');

    showNotification({
      message: (
        <>
          <strong>Tip successfully added to</strong>
          <br />
          <Link to={RouteConfig.tipList.generate(tipList.id)} style={{ color: '#ffffff' }}>
            {tipList.title}
          </Link>
        </>
      ),
    });
  }, [tip, dispatch, auth, showNotification]);

  return (
    <UI.ContextMenu
      className={css.Container}
      align={align}
      button={(
        <UI.Button
          variant="text"
          className={[css.Button, buttonClassName ?? ''].join(' ')}
          onClick={(e) => {
            if (!auth) {
              e.stopPropagation();
              e.preventDefault();

              signInDialog();
              return;
            }

            loadTipLists();
          }}
          style={{
            color: tip.borrowing ? 'var(--color-blue0)' : 'var(--color-gray1)',
            fill: tip.borrowing ? 'var(--color-blue0)' : 'var(--color-gray1)',
          }}
          title={MiscUtils.quantify('Borrower', tip.borrowerCount)}
        >
          <Icons.Star />
          {tip.borrowerCount}
        </UI.Button>
      )}
    >
      <div className={css.Content}>
        <div className={css.Header}>Add to list</div>

        <div className={css.TipLists}>
          {relatedTipLists.length + otherTipLists.length === 0 && (
            <strong>No lists available</strong>
          )}

          {relatedTipLists.length > 0 && (
            <>
              <strong>{`My ${relatedTipLists[0].city.name ?? 'city'} lists`}</strong>
              {relatedTipLists.map((tipList) => (
                <UI.Button
                  className={css.TipList}
                  key={tipList.id}
                  variant="text"
                  onClick={() => addTip(tipList)}
                >
                  {`+ ${tipList.title}`}
                </UI.Button>
              ))}
            </>
          )}

          {otherTipLists.length > 0 && (
            <>
              <strong>All my lists</strong>
              {otherTipLists.map((tipList) => (
                <UI.Button
                  className={css.TipList}
                  key={tipList.id}
                  variant="text"
                  onClick={() => addTip(tipList)}
                >
                  {`+ ${tipList.title}`}
                </UI.Button>
              ))}
            </>
          )}
        </div>

        <div className={css.Footer}>
          or
          {' '}
          <UI.Button
            className={css.Create}
            variant="text"
            color="primary"
            onClick={() => tipListDialog(undefined, tip)}
          >
            Create new list
          </UI.Button>
        </div>
      </div>
    </UI.ContextMenu>
  );
}
