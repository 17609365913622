/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { TipListModel } from 'Models/TipListModel';
import { cityUpdated } from './CityReducer';
import { tipListUpdated, tipListRemoved, tipListFollowChanged } from './commonActions';
import { userUpdated } from './UserReducer';

type TipListState = { [id: string]: TipListModel };
const initialState: TipListState = {};

const tipListSlice = createSlice({
  name: 'tipList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(tipListUpdated, (state, action) => {
      action.payload.forEach((tipList) => {
        state[tipList.id] = tipList;
      });
    });
    builder.addCase(tipListRemoved, (state, action) => {
      delete state[action.payload.id];
    });
    builder.addCase(tipListFollowChanged, (state, action) => {
      state[action.payload.id] = action.payload;
    });

    builder.addCase(cityUpdated, (state, action) => {
      const cities = action.payload;
      const tipLists = Object.values(state);

      cities.forEach((city) => {
        const tipList = tipLists.find((nextTipList) => nextTipList.city.id === city.id);

        if (tipList) {
          state[tipList.id] = { ...tipList, city };
        }
      });
    });
    builder.addCase(userUpdated, (state, action) => {
      const users = action.payload;
      const tipLists = Object.values(state);

      users.forEach((user) => {
        const tipList = tipLists.find((nextTipList) => nextTipList.user.id === user.id);

        if (tipList) {
          state[tipList.id] = { ...tipList, user };
        }
      });
    });
  },
});

export default tipListSlice.reducer;
