import { ImageModel } from "./ImageModel";
import { TipCategoryModel } from "./TipCategoryModel";
import { UserModel } from "./UserModel";
import { VenueModel } from "./VenueModel";

export enum TipStatusEnum {
  DRAFT = 0,
  PUBLISHED = 2,
}

export type TipModel = {
  id: string;
  createdAt: Date;
  modifiedAt: Date;
  title: string | null;
  description: string | null;
  venueId: string | null;
  venue: VenueModel | null;
  imageId: string | null;
  categoryId: string | null;
  category: TipCategoryModel | null;
  userId: string;
  user: UserModel;
  status: TipStatusEnum;
  borrowerCount: number;
  tipListCount: number;
  borrowing: boolean;
  tipListId: string | null;

  image: ImageModel;
  type: "TIP";
};
