/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TipCategoryModel } from 'Models/TipCategoryModel';

type CategoryState = { [id: string]: TipCategoryModel };
const initialState: CategoryState = {};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    categoriesUpdated: (state, action: PayloadAction<TipCategoryModel[]>) => {
      action.payload.forEach((category) => {
        state[category.id] = category;
      });
    },
  },
});

export const { categoriesUpdated } = categoriesSlice.actions;
export default categoriesSlice.reducer;
