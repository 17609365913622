import React from 'react';
import { useModal } from 'Components/UI/Modal';
import Icons from 'Components/Icons';
import UI from 'Components/UI';
import { TipModel, TipStatusEnum } from 'Models/TipModel';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CategorySelectors from 'Store/Selectors/CategorySelectors';
import TipActions from 'Store/Actions/TipActions';
import globalCss from 'Global.module.css';
import { NotificationContext } from 'App';
import TipListActions from 'Store/Actions/TipListActions';
import AuthSelectors from 'Store/Selectors/AuthSelectors';
import { useHistory } from 'react-router-dom';
import RouteConfig from 'RouteConfig';
import Hooks from 'Hooks';
import Image from './Image';
import { Data } from './Types';
import TipLists from './TipLists';
import css from './TipDialog.module.css';

type TipDialogProps = {
  onClose: () => void;
  tip?: TipModel;
};

export default function TipDialog({
  tip,
  onClose,
}: TipDialogProps): JSX.Element {
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, errors, change, valid] = Hooks.useChange<Data>(
    { ...tip, tipLists: [] },
    (nextData) => ({
      title: (nextData.title ?? '').trim().length === 0 ? 'Please enter a title' : undefined,
      description: (nextData.description ?? '').trim().length === 0 ? 'Please enter a description' : undefined,
      venue: !nextData.venue ? 'Please select a venue' : undefined,
      imageData: !(nextData.imageData || nextData.imageId) ? 'Please select an image' : undefined,
      categoryId: (nextData.categoryId ?? '').trim().length === 0 ? 'Please select a category' : undefined,
      tipLists: (nextData.tipLists ?? '').length === 0 ? 'Please add your tip to a list' : undefined,
    }),
  );
  const categories = useSelector(CategorySelectors.all);
  const [pending, setPending] = React.useState<'draft' | 'publish'>();
  const showNotification = React.useContext(NotificationContext);
  const auth = useSelector(AuthSelectors.get);
  const canSave = valid(data, false);

  // Save
  const submit = React.useCallback(
    async (
      nextData: Data,
      status: TipStatusEnum,
      nextTipId?: string,
    ) => {
      setPending(status === TipStatusEnum.DRAFT ? 'draft' : 'publish');

      try {
        if (status === TipStatusEnum.PUBLISHED && !valid()) {
          return;
        }

        let nextImageData: string | null | undefined = nextData.imageData;

        if (!nextImageData) {
          // Set to null if the image has been deleted.
          nextImageData = nextData.imageId ? undefined : null;
        }

        let nextTip: TipModel | null;

        if (!nextTipId) {
          nextTip = await TipActions.create(
            dispatch,
            nextData.title ?? null,
            nextData.description ?? null,
            nextData.venue?.id ?? null,
            nextData.imageData ?? null,
            nextData.categoryId ?? null,
            nextData.tipLists?.map((tipList) => tipList.id) ?? null,
            status,
          );
        } else {
          nextTip = await TipActions.update(
            dispatch,
            nextTipId,
            nextData.title ?? null,
            nextData.description ?? null,
            nextData.venue?.id ?? null,
            nextImageData,
            nextData.categoryId ?? null,
            nextData.tipLists?.map((tipList) => tipList.id) ?? null,
            status,
          );
        }

        // Redirect if the tip is new.
        if (!nextTipId && nextTip) {
          const [tipList] = await TipListActions.list(
            dispatch,
            {
              limit: 1,
              filters: { tipId: nextTip?.id, userIds: auth?.id ? [auth.id] : undefined },
              sort: { followerCount: true },
            },
          );

          if (tipList.length === 1) {
            history.push(RouteConfig.tipList.generate(tipList[0].id, { tipId: nextTip.id }));
          }
        }

        showNotification({
          message: `Your tip has been ${status === TipStatusEnum.PUBLISHED
            ? 'published'
            : 'saved as a draft'}`,
          timeoutMs: 4000,
          type: status === TipStatusEnum.PUBLISHED ? 'neutral' : 'alert',
        });

        onClose();
      } catch (err) {
        //
      } finally {
        setPending(undefined);
      }
    },
    [dispatch, showNotification, onClose, valid, history, auth?.id],
  );

  // Cancel.
  const [confirmDialog, showConfirmDialog, hideConfirmDialog] = useModal(
    <UI.ConfirmDialog
      message="Are you sure you want to discard your changes?"
      confirmLabel="Yes"
      cancelLabel="No"
      onCancel={() => hideConfirmDialog()}
      onConfirm={() => {
        hideConfirmDialog();
        onClose();
      }}
    />,
  );

  const previousData = Hooks.usePreviousState(data)

  // Cancel edit.
  const cancel = React.useCallback(() => {
    if (previousData === undefined || JSON.stringify(previousData) === JSON.stringify(data)) {
      onClose();
      return
    }
    showConfirmDialog()
  }, [data, onClose, previousData, showConfirmDialog]);

  // Remove tip
  const [deleteDialog, showDeleteDialog, hideDeleteDialog] = useModal(
    <UI.ConfirmDialog
      message="Are you sure you want to delete this tip?"
      confirmLabel="Yes"
      cancelLabel="No"
      onCancel={() => hideDeleteDialog()}
      onConfirm={async () => {
        if (!tip) {
          return;
        }

        TipActions.remove(dispatch, tip);

        showNotification({ message: 'Your tip has been deleted', type: 'error' });

        onClose();
        hideDeleteDialog();
      }}
    />,
  );

  const title = data.title ?? '';
  const description = data.description ?? '';
  const categoryId = data.categoryId ?? '';

  return (
    <div className={css.Container}>
      {confirmDialog}
      {deleteDialog}

      <div className={[globalCss.Content, css.Content].join(' ')}>
        <h2 className={css.Title}>
          <span>
            {`${!tip ? 'Create new' : 'Edit'} tip`}
            {data.status === TipStatusEnum.DRAFT && (
              <span style={{ color: 'var(--color-orange0' }}>&nbsp;(draft)</span>
            )}
          </span>

          <UI.Button variant="text" onClick={cancel} className={css.CloseButton}>
            <Icons.Close />
          </UI.Button>
        </h2>

        <div className={css.Fields}>
          <div className={css.Col50}>
            <div>
              <UI.LocationSearch.Venue
                value={data.venue ?? undefined}
                onChange={(nextValue) => change({ venue: nextValue ?? null })}
              />
              <UI.ValidationError>{errors.venue}</UI.ValidationError>
            </div>

            <div>
              <UI.SelectField
                value={categoryId}
                onChange={(e) => change({ categoryId: e.target.value })}
                label="Category"
              >
                {categories.map((category) => (
                  <option value={category.id} key={category.id}>{category.title}</option>
                ))}
              </UI.SelectField>
              <UI.ValidationError>{errors.categoryId}</UI.ValidationError>
            </div>
          </div>

          <div>
            <UI.TextField
              label="Title"
              className={css.Input}
              value={title}
              onChange={(e) => change({ title: e.target.value })}
              maxLength={60}
            />
            <span className={css.CharCounter}>{`${title.length}/60`}</span>
            <UI.ValidationError>{errors.title}</UI.ValidationError>
          </div>

          <div>
            <UI.MultiLineField
              label="Description"
              className={css.Input}
              value={description}
              onChange={(e) => change({ description: e.target.value })}
              maxLength={400}
              style={{ minHeight: 150 }}
            />
            <span className={css.CharCounter}>{`${description.length}/400`}</span>
            <UI.ValidationError>{errors.description}</UI.ValidationError>
          </div>

          <Image
            data={data}
            errors={errors}
            onChange={change}
          />



          <div>
            <TipLists change={change} data={data} userId={auth?.id} tipId={tip?.id} />
            <UI.ValidationError>{errors.tipLists}</UI.ValidationError>
          </div>


          <div className={css.Actions}>
            <UI.Button onClick={cancel} color="secondary" variant="outline">Cancel</UI.Button>


            {tip && (
              <UI.Button color="fourth" onClick={showDeleteDialog}>Delete</UI.Button>
            )}

            <UI.Button
              className={css.ButtonRight}
              onClick={() => { hideConfirmDialog(); submit(data, TipStatusEnum.DRAFT, tip?.id); }}
              color="fifth"
            >
              {pending === 'draft' ? 'Saving draft...' : 'Save draft'}
            </UI.Button>

            <UI.Button
              className={!canSave ? css.Disabled : ''}
              onClick={() => {
                if (pending) {
                  return;
                }

                submit(data, TipStatusEnum.PUBLISHED, tip?.id);
              }}
            >
              {pending === 'publish' ? 'Publishing...' : 'Publish'}
            </UI.Button>
          </div>
        </div>
      </div>
    </div>
  );
}
