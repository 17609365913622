import { TipModel } from 'Models/TipModel';
import Services from 'Services';
import { AppDispatch } from 'Store';
import { tipUpdated, tipRemoved } from 'Store/Reducers/commonActions';

/**
 * Get a tip.
 * @param dispatch Dispatch function.
 * @param id Tip identifier.
 */
async function get(
  dispatch: AppDispatch,
  id: string,
): Promise<TipModel | null> {
  const tip = await Services.Tip.get(id);

  if (tip) {
    dispatch(tipUpdated([tip]));
  }

  return tip;
}

/**
 * List tips.
 * @param dispatch Dispatch function.
 * @param args Listing args.
 */
async function list(
  dispatch: AppDispatch,
  ...args: Parameters<typeof Services.Tip.list>
): Promise<[TipModel[], number]> {
  const [tips, total] = await Services.Tip.list(...args);

  dispatch(tipUpdated(tips));

  return [tips, total];
}

/**
 * Create a tip.
 * @param dispatch Dispatch function.
 * @param args Create args.
 */
async function create(
  dispatch: AppDispatch,
  ...args: Parameters<typeof Services.Tip.create>
): Promise<TipModel | null> {
  const tip = await Services.Tip.create(...args);

  if (tip) {
    dispatch(tipUpdated([tip]));
  }

  return tip;
}

/**
 * Update a tip.
 * @param dispatch Dispatch function.
 * @param args Update args.
 */
async function update(
  dispatch: AppDispatch,
  ...args: Parameters<typeof Services.Tip.update>
): Promise<TipModel | null> {
  const tip = await Services.Tip.update(...args);

  if (tip) {
    dispatch(tipUpdated([tip]));
  }

  return tip;
}

/**
 * Remove a tip.
 * @param dispatch Dispatch function.
 * @param tip Tip to be removed.
 */
function remove(dispatch: AppDispatch, tip: TipModel): void {
  Services.Tip.remove(tip.id);

  dispatch(tipRemoved(tip));
}

export default {
  get,
  list,
  create,
  update,
  remove,
};
