import React from 'react';

export default function Google({ className }: { className?: string }): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 16 17"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M16 8.34891C16 7.79408 15.955 7.23626 15.859 6.69043H8.1604V9.83345H12.569C12.3861 10.8471 11.7983 11.7439 10.9375 12.3137V14.353H13.5677C15.1122 12.9315 16 10.8321 16 8.34891Z"
          fill="#4285F4"
        />
        <path
          d="M8.16042 16.3235C10.3617 16.3235 12.2182 15.6008 13.5707 14.3531L10.9406 12.3138C10.2088 12.8116 9.26408 13.0935 8.16342 13.0935C6.03408 13.0935 4.22865 11.657 3.58085 9.72559H0.866699V11.8279C2.25227 14.5841 5.07438 16.3235 8.16042 16.3235Z"
          fill="#34A853"
        />
        <path
          d="M3.57792 9.72639C3.23602 8.71271 3.23602 7.61505 3.57792 6.60137V4.49902H0.866764C-0.290874 6.8053 -0.290874 9.52245 0.866764 11.8287L3.57792 9.72639Z"
          fill="#FBBC04"
        />
        <path
          d="M8.16042 3.22956C9.32406 3.21157 10.4487 3.64943 11.2914 4.45318L13.6217 2.12291C12.1462 0.737343 10.1878 -0.024419 8.16042 -0.000426538C5.07438 -0.000426538 2.25227 1.73903 0.866699 4.49817L3.57785 6.60051C4.22265 4.66612 6.03109 3.22956 8.16042 3.22956Z"
          fill="#EA4335"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16.3239" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
