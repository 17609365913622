import { UserModel } from 'Models/UserModel';
import ServiceUtils from './ServiceUtils';
import UserService from './UserService';

async function signIn(username: string, password: string): Promise<UserModel | null> {
  try {
    
    const { data } = await ServiceUtils.http.post<any>(
      '/signin',
      { username, password },
    );

    localStorage.setItem("access_token", data.access_token)

    const { data: user } = await ServiceUtils.http.get<UserModel>(
      '/signin',
    );

    return UserService.parse(user);
  } catch (err) {
    return null;
  }
}

async function getAuthedUser(): Promise<UserModel|null> {
  try {
    const { data: user } = await ServiceUtils.http.get<UserModel>('/signin');

    return UserService.parse(user);
  } catch (err) {
    return null;
  }
}

function signOut() {
  localStorage.removeItem('access_token')
}

async function signUp({
  firstName,
  lastName,
  username,
  password,
}: {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
}): Promise<void> {
  try {
    await ServiceUtils.http.post(
      '/signup',
      {
        firstName,
        lastName,
        username,
        password,
      },
    );
  } catch ({ response: { status, data } }) {
    switch (status) {
      case 400:
        throw new Error('Bad request');
      case 409:
        throw new Error('E-mail address already registered');
      default:
        throw new Error('An unexpected error occured');
    }
  }
}

async function verify(code: string): Promise<void> {
  try {
    await ServiceUtils.http.patch('/signup', { code });
  } catch ({ response: { status, data } }) {
    switch (status) {
      case 400:
        throw Error('Invalid verification code');
      default:
        throw Error('An unexpected error occured');
    }
  }
}

export default {
  signIn,
  getAuthedUser,
  signOut,
  signUp,
  verify,
};
