import React from 'react';

import SignIn from './Views/SignIn';
import SignUp from './Views/SignUp';

export type SignInSignUpDialogProps = {
  onClose: () => void;
  initialView?: 'signIn'|'signUp';
};

export default function SignInSignUpDialog({
  onClose,
  initialView = 'signIn',
}: SignInSignUpDialogProps): JSX.Element {
  const [view, setView] = React.useState(initialView);

  return view === 'signIn'
    ? (<SignIn onClose={onClose} goToSignUp={() => setView('signUp')} />)
    : (<SignUp onClose={onClose} goToSignIn={() => setView('signIn')} />);
}
