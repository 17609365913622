import Cards from 'Components/Cards';
import Icons from 'Components/Icons';
import { UserModel } from 'Models/UserModel';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Services from 'Services';
import AuthSelectors from 'Store/Selectors/AuthSelectors';
import TipSelectors from 'Store/Selectors/TipSelectors';
import MiscUtils from 'Utils/MiscUtils';
import UI from 'Components/UI';
import css from './TipsDialog.module.css';

type TipsDialogProps = {
  onClose: () => void;
  user: UserModel;
};

export default function TipsDialog({ onClose, user }: TipsDialogProps): JSX.Element {
  const dispatch = useDispatch();
  const auth = useSelector(AuthSelectors.get);
  const [tipIds, setTipIds] = React.useState<string[]>([]);
  const tips = useSelector(TipSelectors.list(...tipIds));
  const [total, setTotal] = React.useState(0);
  const myTips = auth && auth.id === user.id;
  const tipsRef = React.useRef<HTMLDivElement>(null);

  // Load more function.
  const funcInitialized = React.useRef<number>(0);
  const loadMore = React.useMemo(
    () => {
      const now = new Date().getTime();
      funcInitialized.current = now;

      return Services.Tip.getLoadMoreFunc(
        ([nextTips, nextTotal]) => {
          if (funcInitialized.current === now) {
            setTipIds(nextTips.map((tip) => tip.id));
            setTotal(nextTotal);
          }
        },
        dispatch,
        { filters: { userIds: [user.id] }, limit: 8 },
      );
    },
    [user, dispatch],
  );

  // Initial load.
  React.useEffect(() => {
    loadMore();

    const ref = tipsRef.current;

    if (!ref) {
      return () => {};
    }

    const onScroll = async () => {
      if (ref.scrollHeight - ref.clientHeight - ref.scrollTop < 400) {
        loadMore();
      }
    };

    ref.addEventListener('scroll', onScroll);

    return () => ref.removeEventListener('scroll', onScroll);
  }, [loadMore]);

  return (
    <section className={css.Container}>
      <div className={css.Header}>
        <h5>
          {`All ${myTips ? 'my' : MiscUtils.formatNameApos(user.firstName)} tips (${total})`}
        </h5>

        <UI.Button variant="text" onClick={onClose} className={css.CloseButton}>
          <Icons.Close />
        </UI.Button>
      </div>

      <div className={css.Content} ref={tipsRef}>
        <div className={css.Tips}>
          {tips.map((tip) => (
            <Cards.Tip
              size="small"
              key={tip.id}
              className={css.Tip}
              onNavigate={onClose}
              value={tip}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
