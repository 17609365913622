import UI from 'Components/UI';
import Icons from 'Components/Icons';
import { SearchModel } from 'Models/SearchModel';
import React from 'react';
import MiscUtils from 'Utils/MiscUtils';
import accountIcon from 'Assets/Icons/account.svg';
import { Link, useHistory } from 'react-router-dom';
import RouteConfig from 'RouteConfig';
import Services from 'Services';
import { UserModel } from 'Models/UserModel';
import TipListActions from 'Store/Actions/TipListActions';
import { useDispatch } from 'react-redux';
import css from './MainSearch.module.css';

type MainSearchProps = {
  className?: string;
  placeholder?: string;
  wrapperClassName?: string;
  inputClassName?: string;
};

export default function MainSearch({
  className,
  wrapperClassName,
  inputClassName,
  placeholder = 'Search',
}: MainSearchProps): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const [result, setResult] = React.useState<SearchModel|null>(null);
  const transitionTimeMs = 300;
  const pending = React.useRef(false);
  const nextQuery = React.useRef('');
  const [expanded, setExpanded2] = React.useState(false);
  const selfRef = React.useRef<HTMLElement>(null);
  const noResult = React.useMemo(
    () => (result && Object
      .keys(result)
      .reduce((prev, curr) => prev + result[curr as keyof typeof result].length, 0) === 0) ?? true,
    [result],
  );
  const [zIndex, setZIndex] = React.useState<number>();
  const nextZIndex = React.useRef<number>();

  const setExpanded = React.useCallback(async (nextExpanded) => {
    setExpanded2(nextExpanded);
    nextZIndex.current = nextExpanded ? 1 : undefined;

    if (nextExpanded) {
      setZIndex(nextZIndex.current);
    } else {
      await MiscUtils.sleep(transitionTimeMs);
      setZIndex(nextZIndex.current);
    }
  }, []);

  // Perform search.
  const search = React.useCallback(async (query: string) => {
    nextQuery.current = query;

    if (pending.current) {
      return;
    }

    pending.current = true;

    const nextResult = await Services.Search.get(query);
    setResult(
      nextResult
        ? {
          cities: nextResult.cities,
          tipLists: nextResult.tipLists,
          // tips: nextResult.tips,  TODO: Uncomment
          tips: [],
          users: nextResult.users,
          // venues: nextResult.venues,  TODO: Uncomment
          venues: [],
        }
        : null,
    );
    setExpanded(nextResult !== null);

    // Throttle.
    await MiscUtils.sleep(300);

    pending.current = false;

    if (query !== nextQuery.current) {
      search(nextQuery.current);
    }
  }, [setExpanded]);

  // Blur event.
  React.useEffect(() => {
    const ref = selfRef.current;

    if (!ref) {
      return () => {};
    }

    const clickOutside = (e: MouseEvent) => {
      if (ref.contains(e.target as Node)) {
        return;
      }

      setExpanded(false);
    };

    document.addEventListener('mousedown', clickOutside);

    return () => document.removeEventListener('mousedown', clickOutside);
  }, [setExpanded]);

  const parseString = React.useCallback(
    (str: string) => str
      .split(RegExp(`(${nextQuery.current})`, 'ig'))
      .map(
        (item, idx) => (item.toLowerCase() === nextQuery.current.toLowerCase()
          /* eslint-disable */
          ? (<strong key={idx}>{item}</strong>)
          /* eslint-enable */
          : item),
      ),
    [],
  );

  // Navigate to user.
  const navigateToUser = React.useCallback(async (user: UserModel) => {
    const [topTipList] = await TipListActions.list(
      dispatch,
      { limit: 1, filters: { userIds: [user.id] }, sort: { followerCount: true } },
    );

    if (topTipList.length === 1) {
      // Tip list exists, navigate to it.
      history.push(RouteConfig.tipList.generate(topTipList[0].id));
    } else {
      // User has not tip lists, navigate to profile.
      history.push(RouteConfig.profile.generate(user.id));
    }

    setExpanded(false);
  }, [setExpanded, dispatch, history]);

  return (
    <section
      ref={selfRef}
      className={[css.Container, className ?? ''].join(' ')}
      style={{ transition: 'border-radius 100ms', zIndex }}
    >
      <div className={[css.Wrapper, wrapperClassName ?? ''].join(' ')}>
        <div className={[css.InputWrapper, inputClassName ?? ''].join(' ')}>
          <Icons.Search className={css.Icon} />

          <input
            type="text"
            className={css.Input}
            placeholder={placeholder}
            onChange={(e) => search(e.target.value)}
            onFocus={() => {
              if (!noResult) {
                setExpanded(true);
              }
            }}
          />
        </div>

        <UI.Collapsible
          expanded={expanded}
          className={css.SearchResultWrapper}
          transitionTimeMs={transitionTimeMs}
        >
          <div className={css.SearchResult}>
            {noResult && (
              <span className={css.Details}>Couldn&apos;t find any results</span>
            )}

            {(result?.cities.length ?? 0) > 0 && (
              <div className={css.Category}>
                <strong className={css.Title}>Cities</strong>

                {result?.cities.map((city) => (
                  <Link
                    to={RouteConfig.city.generate(city.id)}
                    className={css.Item}
                    key={city.id}
                    onClick={() => setExpanded(false)}
                  >
                    <span>{parseString(MiscUtils.getCityCountry({ city }))}</span>

                    <span className={css.Details}>
                      {parseString(`· ${MiscUtils.getTipTipListCount(city)}`)}
                    </span>
                  </Link>
                ))}
              </div>
            )}

            {(result?.venues.length ?? 0) > 0 && (
              <div className={css.Category}>
                <strong className={css.Title}>Venues</strong>

                {result?.venues.map((venue) => (
                  <Link
                    to="#venue"
                    className={css.Item}
                    key={venue.id}
                    onClick={() => setExpanded(false)}
                  >
                    <span>
                      {parseString(`${venue.name}''}`)}
                    </span>

                    <span className={css.Details}>
                      {`· ${MiscUtils.quantify('Tip', 0)}`}
                    </span>
                  </Link>
                ))}
              </div>
            )}

            {(result?.tips.length ?? 0) > 0 && (
              <div className={css.Category}>
                <strong className={css.Title}>Tips</strong>

                {result?.tips.map((tip) => (
                  <Link
                    to="#tip"
                    className={css.Item}
                    key={tip.id}
                    onClick={() => setExpanded(false)}
                  >
                    <span>{parseString(tip?.title ?? '')}</span>
                  </Link>
                ))}
              </div>
            )}

            {(result?.tipLists.length ?? 0) > 0 && (
              <div className={css.Category}>
                <strong className={css.Title}>Lists</strong>

                {result?.tipLists.map((tipList) => (
                  <Link
                    to={RouteConfig.tipList.generate(tipList.id)}
                    className={css.Item}
                    key={tipList.id}
                    onClick={() => setExpanded(false)}
                  >
                    <span>{parseString(tipList.title)}</span>

                    <span className={css.Details}>
                      {`· ${MiscUtils.quantify('Tip', tipList.tipCount)}`}
                    </span>
                  </Link>
                ))}
              </div>
            )}

            {(result?.users.length ?? 0) > 0 && (
              <div className={css.Category}>
                <strong className={css.Title}>Profiles</strong>

                {result?.users.map((user) => (
                  <span
                    className={css.Item}
                    key={user.id}
                    onClick={() => navigateToUser(user)}
                    onKeyDown={() => {
                      //
                    }}
                    role="button"
                    tabIndex={-1}
                  >
                    <img
                      src={user.profileImage.thumb ?? accountIcon}
                      alt={user.profileImage.altText}
                      className={css.ProfileImage}
                    />

                    <span className={css.Highlight}>
                      {parseString(MiscUtils.getFullName(user))}
                    </span>

                    <span className={css.Details}>{`· ${MiscUtils.getTipTipListCount(user)}`}</span>
                  </span>
                ))}
              </div>
            )}
          </div>
        </UI.Collapsible>
      </div>
    </section>
  );
}
