import React from 'react';
import css from './Spinner.module.css';

type SpinnerProps = {
  margin?: string;
};

const Spinner = ({ margin }: SpinnerProps): JSX.Element => (
  <div style={{ margin: `${margin}` }} className={css.spinner} />
);

export default Spinner;
