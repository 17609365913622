import { TipListModel } from 'Models/TipListModel';
import Services from 'Services';
import { UserModel } from 'Models/UserModel';
import { TipModel } from 'Models/TipModel';
import { AppDispatch } from 'Store';
import {
  tipListUpdated, tipListRemoved, tipListFollowChanged, tipUpdated,
} from 'Store/Reducers/commonActions';

/**
 * Get tip list.
 * @param dispatch Dispatch function.
 * @param id Tip list identifier.
 */
async function get(
  dispatch: AppDispatch,
  id: string,
): Promise<TipListModel | null> {
  const tipList = await Services.TipList.get(id);

  if (tipList) {
    // Put tip list.
    dispatch(tipListUpdated([tipList]));
  }

  return tipList;
}

/**
 * List tip lists.
 * @param dispatch Dispatch function.
 * @param args Listing arguments.
 */
async function list(
  dispatch: AppDispatch,
  ...args: Parameters<typeof Services.TipList.list>
): Promise<[TipListModel[], number]> {
  const [tipLists, total] = await Services.TipList.list(...args);

  // Put tip lists.
  dispatch(tipListUpdated(tipLists));

  return [tipLists, total];
}

/**
 * Create tip list.
 * @param dispatch Dispatch function.
 * @param args Create arguments.
 */
async function create(
  dispatch: AppDispatch,
  ...args: Parameters<typeof Services.TipList.create>
): Promise<TipListModel> {
  const tipList = await Services.TipList.create(...args);

  // Put tip list.
  dispatch(tipListUpdated([tipList]));

  return tipList;
}

/**
 * Update tip list.
 * @param dispatch Dispatch function.
 * @param args Update arguments.
 */
async function update(
  dispatch: AppDispatch,
  ...args: Parameters<typeof Services.TipList.update>
): Promise<TipListModel> {
  const tipList = await Services.TipList.update(...args);

  // Update tip list.
  dispatch(tipListUpdated([tipList]));

  return tipList;
}

/**
 * Remove tip list.
 * @param dispatch Dipsatch function.
 * @param tipList Tip list to remove.
 */
function remove(
  dispatch: AppDispatch,
  tipList: TipListModel,
): void {
  Services.TipList.remove(tipList.id);

  dispatch(tipListRemoved(tipList));
}

/**
 * Toggle following status of a tip list.
 * @param dispatch Dispatch function.
 * @param follower Following/unfollowing user.
 * @param tipList Tip list that is being followed/unfollowed.
 */
async function toggleFollowing(
  dispatch: AppDispatch,
  follower: UserModel,
  tipList: TipListModel,
): Promise<void> {
  const nextTipList = await Services.TipList.followUnfollow(tipList.id, !tipList.following);

  if (!nextTipList) {
    return;
  }

  // Update followed tip list.
  dispatch(tipListFollowChanged(nextTipList));
}

/**
 * Add tips to tip list.
 * @param dispatch Dispatch function.
 * @param tipListId Tip list id.
 * @param tips Tips to add.
 * @param userId User performing the adding.
 */
async function addTips(
  dispatch: AppDispatch,
  tipList: TipListModel,
  tips: TipModel[],
  userId: string,
): Promise<void> {
  await Services.TipList.addTips(tipList.id, tips.map((tip) => tip.id));
  const updatedTips = tips.map((tip) => ({
    ...tip,
    tipListCount: tip.tipListCount + 1,
    borrowing: tip.userId !== userId,
    borrowerCount: (tip.userId === userId || tip.borrowing)
      ? tip.borrowerCount
      : tip.borrowerCount + 1,
  }));
  dispatch(tipUpdated(updatedTips));
}

/**
 * Remove tips from tip list.
 * @param dispatch Dispatch function.
 * @param tipListId Tip list id.
 * @param tips Tips to remove.
 * @param userId User performing the removal.
 */
async function removeTips(
  dispatch: AppDispatch,
  tipListId: string,
  tips: TipModel[],
  userId: string,
): Promise<void> {
  await Services.TipList.removeTips(tipListId, tips.map((tip) => tip.id));

  const updatedTips = tips.map((tip) => ({
    ...tip,
    tipListCount: tip.tipListCount - 1,
    borrowing: false,
    borrowerCount: tip.userId === userId
      ? tip.borrowerCount
      : tip.borrowerCount - 1,
  }));
  dispatch(tipUpdated(updatedTips));
}

export default {
  get,
  list,
  create,
  update,
  remove,
  toggleFollowing,
  addTips,
  removeTips,
};
