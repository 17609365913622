import React from 'react';

import css from './Button.module.css';

export type ButtonProps = React.PropsWithChildren<
React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
> & {
  className?: string;
  variant?: 'text'|'outline'|'fill';
  color?: 'primary'|'secondary'|'third'|'fourth'|'fifth';
};

export default function Button({
  children,
  className,
  variant = 'fill',
  color = 'primary',
  ...props
}: ButtonProps): JSX.Element {
  return (
    <button
      {...props}
      className={[
        css.Container,
        className ?? '',
        {
          text: css.Text,
          outline: css.Outline,
          fill: css.Fill,
        }[variant],
        {
          primary: css.Primary,
          secondary: css.Secondary,
          third: css.Third,
          fourth: css.Fourth,
          fifth: css.Fifth,
        }[color],
      ].join('  ')}
    >
      {children}
    </button>
  );
}
