import React from 'react';
import Icons from 'Components/Icons';
import { TipListModel } from 'Models/TipListModel';
import { Link } from 'react-router-dom';
import RouteConfig from 'RouteConfig';
import MiscUtils from 'Utils/MiscUtils';
import { ModalControllerContext, NotificationContext } from 'App';
import { useDispatch, useSelector } from 'react-redux';
import AuthSelectors from 'Store/Selectors/AuthSelectors';
import TipListActions from 'Store/Actions/TipListActions';
import accountIcon from 'Assets/Icons/account.svg';
import UI from 'Components/UI';
import commonCss from './Common.module.css';

type TipListProps = {
  value: TipListModel;
  className?: string;
  size?: 'small' | 'medium';
  onNavigate?: () => void;
};

export default function TipList({
  value,
  className,
  size = 'medium',
  onNavigate,
}: TipListProps): JSX.Element {
  const dispatch = useDispatch();
  const { tipListDialog, signInDialog } = React.useContext(ModalControllerContext);
  const auth = useSelector(AuthSelectors.get);
  const showOptions = auth?.id === value.user.id;
  const showNotification = React.useContext(NotificationContext);

  // Follow/unfollow tip list.
  const toggleFollowing = React.useCallback(
    async (nextTipList: TipListModel) => {
      if (!auth) {
        signInDialog();
        return;
      }

      await TipListActions.toggleFollowing(dispatch, auth, nextTipList);

      showNotification({
        message: (
          <>
            <strong>
              {!nextTipList.following ? 'You\'re now following' : 'You\'ve unfollowed'}
            </strong>
            <br />
            <Link to={RouteConfig.tipList.generate(nextTipList.id)} style={{ color: '#ffffff' }}>
              {nextTipList.title}
            </Link>
          </>
        ),
        type: !nextTipList.following ? 'neutral' : 'alert',
      });
    },
    [dispatch, showNotification, signInDialog, auth],
  );

  return (
    <div
      className={[
        commonCss.Container,
        className ?? '',
        { small: commonCss.Small, medium: commonCss.Medium }[size],
      ].join(' ')}
    >
      <div className={commonCss.Header}>
        <Link to={RouteConfig.tipList.generate(value.id)}>
          <img
            src={value.image.medium ?? value.city?.image.medium}
            alt=""
            className={commonCss.Image}
          />
        </Link>

        {showOptions && (
          <UI.ContextMenu
            className={commonCss.Options}
            button={(
              <UI.Button variant="text" className={commonCss.OptionsButton}>
                <Icons.Menu className={commonCss.OptionsIcon} />
              </UI.Button>
            )}
          >
            {auth?.id === value.user.id && (
              <UI.Button
                variant="text"
                className={[commonCss.EditButton, commonCss.Option].join(' ')}
                onClick={() => tipListDialog(value)}
              >
                <Icons.Edit className={commonCss.Icon} />

                <span>Edit list</span>
              </UI.Button>
            )}
          </UI.ContextMenu>
        )}
      </div>

      <div className={commonCss.Main}>
        <div className={commonCss.Actions}>
          <img src={value.user.profileImage.thumb ?? accountIcon} alt="" className={commonCss.Avatar} />

          <UI.Button
            variant="text"
            className={[
              commonCss.Action,
              auth && value.following ? commonCss.Active : '',
            ].join(' ')}
            title={MiscUtils.quantify('Follower', value.followerCount)}
            onClick={() => toggleFollowing(value)}
            disabled={auth?.id === value.user.id}
          >
            <Icons.Star />

            <span>{value.followerCount.toLocaleString('en-US')}</span>
          </UI.Button>
        </div>

        <div className={commonCss.Content}>
          <Link to={RouteConfig.tipList.generate(value.id)} className={commonCss.Title}>
            {value.title}
          </Link>

          <h3 className={commonCss.Location}>
            {MiscUtils.delimit(undefined, value.city?.fullName)}
          </h3>

          <Link
            to={RouteConfig.profile.generate(value.user.id)}
            className={commonCss.User}
            onClick={onNavigate}
          >
            {[value.user.firstName, value.user.lastName].join(' ')}
          </Link>

          <p className={commonCss.Description}>{value.description}</p>
        </div>
      </div>
    </div>
  );
}
