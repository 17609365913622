import React from 'react';
import { useModal } from 'Components/UI/Modal';
import EditProfileDialog from 'Dialogs/EditProfileDialog';
import BorrowersDialog from 'Dialogs/BorrowersDialog';
import FollowedTipListsDialog from 'Dialogs/FollowedTipListsDialog';
import FollowersDialog from 'Dialogs/FollowersDialog';
import TipsDialog from 'Dialogs/TipsDialog';
import { SignInDialog, SignUpDialog } from 'Dialogs/SignInSignUpDialog';
import { UserModel } from 'Models/UserModel';
import TipDialog from 'Dialogs/TipDialog';
import TipListDialog from 'Dialogs/TipListDialog';
import { TipModel } from 'Models/TipModel';
import { TipListModel } from 'Models/TipListModel';

export default function useModalController(): [
  JSX.Element,
  {
    tipDialog: (tip?: TipModel) => Promise<void>;
    signInDialog: () => Promise<void>;
    signUpDialog: () => Promise<void>;
    tipListDialog: (
      tipList?: TipListModel,
      tip?: TipModel,
      saveCallback?: (tipList: TipListModel) => void,
    ) => Promise<void>;
    editProfileDialog: () => Promise<void>;
    borrowersDialog: (user: UserModel) => Promise<void>;
    followedTipListsDialog: (user: UserModel) => Promise<void>;
    followersDialog: (user: UserModel) => Promise<void>;
    tipsDialog: (user: UserModel) => Promise<void>;
  },
] {
  const [signIn, showSignIn, hideSignIn] = useModal(
    <SignInDialog onClose={() => hideSignIn()} />,
  );

  const [signUp, showSignUp, hideSignUp] = useModal(
    <SignUpDialog onClose={() => hideSignUp()} />,
  );

  const [editProfile, showEditProfile, hideEditProfile] = useModal(
    <EditProfileDialog onClose={() => hideEditProfile()} />,
  );

  const [borrowers, showBorrowers, hideBorrowers] = useModal(
    (user: UserModel) => (
      <BorrowersDialog onClose={() => hideBorrowers()} user={user} />
    ),
  );

  const [followedTipLists, showFollowedTipLists, hideFollowedTipLists] = useModal(
    (user: UserModel) => (
      <FollowedTipListsDialog onClose={() => hideFollowedTipLists()} user={user} />
    ),
  );

  const [followers, showFollowers, hideFollowers] = useModal(
    (user: UserModel) => (
      <FollowersDialog onClose={() => hideFollowers()} user={user} />
    ),
  );

  const [tips, showTips, hideTips] = useModal(
    (user: UserModel) => (
      <TipsDialog onClose={() => hideTips()} user={user} />
    ),
  );

  const [tipDialog, showTipDialog, hideTipDialog] = useModal(
    (tip?: TipModel) => (
      <TipDialog onClose={() => hideTipDialog()} tip={tip} />
    ),
    false,
  );

  const [tipListDialog, showTipListDialog, hideTipListDialog] = useModal(
    (tipList?: TipListModel, tip?: TipModel, saveCallback?: (tipList: TipListModel) => void) => (
      <TipListDialog
        onClose={() => hideTipListDialog()}
        tipList={tipList}
        tip={tip}
        saveCallback={saveCallback}
      />
    ),
    false,
  );

  return [
    (
      <>
        {tipDialog}
        {signIn}
        {signUp}
        {editProfile}
        {tipListDialog}
        {borrowers}
        {followedTipLists}
        {followers}
        {tips}
      </>
    ),
    {
      tipDialog: showTipDialog,
      signInDialog: showSignIn,
      signUpDialog: showSignUp,
      tipListDialog: showTipListDialog,
      editProfileDialog: showEditProfile,
      borrowersDialog: showBorrowers,
      followedTipListsDialog: showFollowedTipLists,
      followersDialog: showFollowers,
      tipsDialog: showTips,
    },
  ];
}
