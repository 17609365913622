import React, { useEffect, useState } from 'react';
import { TipModel } from 'Models/TipModel';
import { ModalControllerContext, NotificationContext } from 'App';
import { useDispatch, useSelector } from 'react-redux';
import AuthSelectors from 'Store/Selectors/AuthSelectors';
import TipListActions from 'Store/Actions/TipListActions';
import TipListSelectors from 'Store/Selectors/TipListSelectors';
import UI from 'Components/UI';
import { TipListModel } from 'Models/TipListModel';
import { Link } from 'react-router-dom';
import RouteConfig from 'RouteConfig';
import css from './MoveToList.module.css';

type MoveToListProps = {
  tip: TipModel;
  align?: 'left' | 'right';
  top: number;
  left: number;
  open: boolean;
  setOpen: (state: boolean) => void;
  onRemoveTipFromTipList?: (tip: TipModel) => void;
};

export default function MoveToList({
  top,
  left,
  tip,
  align = 'right',
  open,
  setOpen,
  onRemoveTipFromTipList,
}: MoveToListProps): JSX.Element {
  const dispatch = useDispatch();
  const { tipListDialog } = React.useContext(ModalControllerContext);
  const auth = useSelector(AuthSelectors.get);
  const cityId = tip.venue?.cityId;
  const userId = auth?.id;
  const tipId = tip.id;
  const [relatedTipListIds, setRelatedTipListIds] = React.useState<string[]>([]);
  const relatedTipLists = useSelector(TipListSelectors.list(...relatedTipListIds));
  const [ohterTipListIds, setOtherTipListIds] = React.useState<string[]>([]);
  const otherTipLists = useSelector(TipListSelectors.list(...ohterTipListIds));
  const showNotification = React.useContext(NotificationContext);
  const [adding, setAdding] = useState<string[]>([]);

  // Load all tip lists given query args.
  const loadAllTipLists = React.useCallback(
    async (args: Parameters<typeof TipListActions.list>[1]): Promise<TipListModel[]> => {
      const [nextTipLists] = await TipListActions.list(dispatch, args);

      if (nextTipLists.length < (args?.limit ?? 20)) {
        return [...nextTipLists];
      }

      return [
        ...nextTipLists,
        ...await loadAllTipLists({ ...args, offset: (args?.offset ?? 0) + 1 }),
      ];
    },
    [dispatch],
  );

  // Load all tip lists.
  const loadTipLists = React.useCallback(async () => {
    setRelatedTipListIds([]);
    setOtherTipListIds([]);

    if (!userId) {
      return;
    }

    loadAllTipLists({
      limit: 100,
      offset: 0,
      filters: { userIds: [userId], '!tipId': tipId, cityIds: cityId ? [cityId] : undefined },
    }).then((nextTipLists) => setRelatedTipListIds(nextTipLists.map((tipList) => tipList.id)));

    loadAllTipLists({
      limit: 100,
      offset: 0,
      filters: { userIds: [userId], '!tipId': tipId, '!cityIds': cityId ? [cityId] : undefined },
    }).then((nextTipLists) => setOtherTipListIds(nextTipLists.map((tipList) => tipList.id)));
  }, [loadAllTipLists, cityId, userId, tipId]);

  const moveTip = React.useCallback(async (tipList: TipListModel) => {
    if (!onRemoveTipFromTipList) {
      return;
    }

    await TipListActions.addTips(dispatch, tipList, [tip], auth?.id ?? '');
    onRemoveTipFromTipList(tip);
    loadTipLists();
    showNotification({
      message: (
        <>
          <strong>Tip successfully moved to</strong>
          <br />
          <Link to={RouteConfig.tipList.generate(tipList.id)} style={{ color: '#ffffff' }}>
            {tipList.title}
          </Link>
        </>
      ),
    });
  }, [tip, dispatch, auth, showNotification, loadTipLists, onRemoveTipFromTipList]);

  useEffect(() => {
    if (open) {
      loadTipLists();
    }
  }, [loadTipLists, open]);

  return (
    <UI.ProgMenu
      progToggle={open}
      setProgToggle={setOpen}
      className={css.Container}
      align={align}
      top={top}
      left={left}
    >
      <div className={css.Content}>
        <div className={css.Header}>Move tip to another list</div>

        <div className={css.TipLists}>
          <div className={css.Subtitle}>
            Remove tip from
            {' "'}
            {tip.title}
            {'" '}
            and add to another list
          </div>
          {relatedTipLists.length + otherTipLists.length === 0 && (
            <strong>No lists available</strong>
          )}

          {relatedTipLists.length > 0 && (
            <>
              <strong>{`My ${relatedTipLists[0].city.name ?? 'city'} lists`}</strong>
              {relatedTipLists.map((tipList) => (
                <UI.Button
                  className={css.TipList}
                  key={tipList.id}
                  variant="text"
                  onClick={() => { moveTip(tipList); setAdding((prev) => [...prev, tipList.id]); }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {adding.includes(tipList.id) ? <UI.Spinner margin="0 10px 0 0" /> : '+'}
                    {` ${tipList.title}`}
                  </div>
                </UI.Button>
              ))}
            </>
          )}

          {otherTipLists.length > 0 && (
            <>
              <strong>All my lists</strong>
              {otherTipLists.map((tipList) => (
                <UI.Button
                  className={css.TipList}
                  key={tipList.id}
                  variant="text"
                  onClick={() => { moveTip(tipList); setAdding((prev) => [...prev, tipList.id]); }}
                >
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {adding.includes(tipList.id) ? <UI.Spinner margin="0 10px 0 0" /> : '+'}
                    {` ${tipList.title}`}
                  </div>
                </UI.Button>
              ))}
            </>
          )}
        </div>

        <div className={css.Footer}>
          or
          {' '}
          <UI.Button
            className={css.Create}
            variant="text"
            color="primary"
            onClick={() => { tipListDialog(undefined, tip); setOpen(true); }}
          >
            Create new list
          </UI.Button>
        </div>
      </div>
    </UI.ProgMenu>
  );
}
