import React from 'react';
import { useModal } from 'Components/UI/Modal';
import globalCss from 'Global.module.css';
import Icons from 'Components/Icons';
import { TipListModel } from 'Models/TipListModel';
import { Link, useHistory } from 'react-router-dom';
import RouteConfig from 'RouteConfig';
import UI from 'Components/UI';
import { useDispatch, useSelector } from 'react-redux';
import TipListActions from 'Store/Actions/TipListActions';
import { TipModel } from 'Models/TipModel';
import AuthSelectors from 'Store/Selectors/AuthSelectors';
import { NotificationContext } from 'App';
import Hooks from 'Hooks';
import css from './TipListDialog.module.css';

type Data = Partial<Pick<TipListModel, 'title'|'description'|'city'> & { duplicateTitle: boolean }>;

type TipListDialogProps = {
  onClose: () => void;
  tipList?: TipListModel;
  tip?: TipModel;
  saveCallback?: (tipList: TipListModel) => void;
};

export default function TipListDialog({
  tipList,
  tip,
  onClose,
  saveCallback,
}: TipListDialogProps): JSX.Element {
  const dispatch = useDispatch();
  const auth = useSelector(AuthSelectors.get);
  const [data, errors, change, valid] = Hooks.useChange<Data>(
    { ...(tipList ?? {}), duplicateTitle: false },
    (nextData) => ({
      title: (nextData.title ?? '').trim().length === 0 ? 'Please enter a title' : undefined,
      city: !nextData.city ? 'Please select a city' : undefined,
      duplicateTitle: nextData.duplicateTitle ? 'You already have a list with this title for this city' : undefined,
    }),
  );
  const history = useHistory();
  const tipListId = tipList?.id;
  const showNotification = React.useContext(NotificationContext);

  // Save
  const [pending, setPending] = React.useState(false);
  const save = React.useCallback(
    async (nextData: Data, nextTipListId: string | undefined) => {
      if (pending) {
        return;
      }

      setPending(true);

      try {
        if (!valid()) {
          return;
        }

        let nextTipList: TipListModel | null = null;

        if (!nextTipListId) {
          nextTipList = await TipListActions.create(
            dispatch,
            nextData.title ?? null,
            nextData.description ?? "",
            nextData.city?.id ?? null,
          );

          history.push(RouteConfig.tipList.generate(nextTipList.id));
        } else {
          nextTipList = await TipListActions.update(
            dispatch,
            nextTipListId,
            nextData.title ?? null,
            nextData.description ?? "",
            nextData.city?.id ?? null,
          );
        }

        if (tip?.id) {
          await TipListActions.addTips(dispatch, nextTipList, [tip], auth?.id ?? '');

          showNotification({
            message: (
              <>
                <strong>Tip successfully added to</strong>
                <br />
                <Link
                  to={RouteConfig.tipList.generate(nextTipList.id)}
                  style={{ color: '#ffffff' }}
                >
                  {nextTipList.title}
                </Link>
              </>
            ),
          });
        }

        showNotification({
          message: `Your${!nextTipListId ? ' new' : ''} list has been published`,
          timeoutMs: 4000,
        });

        if (saveCallback) {
          saveCallback(nextTipList);
        }

        onClose();
      } catch (err) {
        if (err.message === '409') {
          valid({ duplicateTitle: true });
        } else {
          showNotification({ message: 'An unexpected error occured', type: 'error' });
        }
      } finally {
        setPending(false);
      }
    },
    [pending, dispatch, history, tip, onClose, auth, valid, showNotification, saveCallback],
  );

  // Cancel.
  const [confirmDialog, showConfirmDialog, hideConfirmDialog] = useModal(
    <UI.ConfirmDialog
      message="Are you sure you want to discard your changes?"
      confirmLabel="Yes"
      cancelLabel="No"
      onCancel={() => hideConfirmDialog()}
      onConfirm={() => {
        hideConfirmDialog();
        onClose();
      }}
    />,
  );

  const previousData = Hooks.usePreviousState(data)

  // Cancel edit.
  const cancel = React.useCallback(() => {
    if (previousData === undefined || JSON.stringify(previousData) === JSON.stringify(data)) {
      onClose();
      return
    }
    showConfirmDialog()
  }, [data, onClose, previousData, showConfirmDialog]);

  const title = data.title ?? '';
  const description = data.description ?? '';

  return (
    <div className={css.Container}>
      {confirmDialog}

      <div className={[globalCss.Content, css.Content].join(' ')}>
        <h2 className={css.Title}>
          <span>
            {`${tipListId === undefined ? 'Create new' : 'Edit'} list`}
          </span>

          <UI.Button variant="text" onClick={cancel} className={css.CloseButton}>
            <Icons.Close />
          </UI.Button>
        </h2>

        <div className={css.Fields}>
          <div>
            <UI.LocationSearch.City
              className={css.Input}
              value={data.city ?? undefined}
              onChange={(nextValue) => change({ city: nextValue })}
            />
            <UI.ValidationError>{errors.city}</UI.ValidationError>
          </div>

          <div>
            <UI.TextField
              label="Title"
              className={css.Input}
              value={title}
              onChange={(e) => change({ title: e.target.value, duplicateTitle: false })}
              maxLength={60}
            />
            <span className={css.CharCounter}>{`${title.length}/60`}</span>
            <UI.ValidationError>{errors.title ?? errors.duplicateTitle}</UI.ValidationError>
          </div>

          <div>
            <UI.MultiLineField
              label="Description"
              className={css.Input}
              value={description}
              onChange={(e) => change({ description: e.target.value })}
              maxLength={400}
              style={{ minHeight: 150 }}
            />
            <span className={css.CharCounter}>{`${description.length}/400`}</span>
            <UI.ValidationError>{errors.description}</UI.ValidationError>
          </div>

          <div className={css.Actions}>
            <div>
              <UI.Button onClick={cancel} color="secondary" variant="outline">Cancel</UI.Button>
            </div>

            <div>
              <UI.Button onClick={() => save(data, tipListId)}>
                {tipListId && (pending ? 'Saving...' : 'Save')}
                {!tipListId && (pending ? 'Publishing...' : 'Publish')}
              </UI.Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
