import UI from 'Components/UI';
import React from 'react';
import MiscUtils from 'Utils/MiscUtils';
import { TipModel } from 'Models/TipModel';
import { NotificationContext } from 'App';
import css from './Image.module.css';

type Data = Partial<Pick<TipModel, 'image' | 'imageId'> & { imageData: string; }>;

type ImageProps = {
  className?: string;
  data: Data;
  errors: Partial<{ [key in keyof Data]: string }>;
  onChange: (data: Data) => void;
};

export default function Image({
  className,
  data,
  errors,
  onChange,
}: ImageProps): JSX.Element {
  const imageFileRef = React.useRef<HTMLInputElement>(null);
  const showNotification = React.useContext(NotificationContext);

  const setImage = React.useCallback(async (file: File) => {
    try {
      onChange({ imageData: await MiscUtils.imageToBase64(file) });
    } catch (err) {
      showNotification({ message: 'Invalid image', type: 'error' });
    }
  }, [onChange, showNotification]);

  const browseImages = () => {
    if (imageFileRef.current) {
      imageFileRef.current.click();
    }
  };

  const imageData = data.imageData ?? data.image?.medium ?? undefined;

  return (
    <div className={className}>
      <div className={css.Image}>
        {data.imageData || data.imageId
          ? (
            <div className={css.Preview}>
              <img src={imageData} alt="Tip cover" />

              <UI.Button
                variant="text"
                onClick={() => onChange({ imageData: undefined, imageId: null })}
                className={css.RemoveButton}
              >
                Remove image
              </UI.Button>
            </div>
          )
          : (
            <UI.DropArea
              onDrop={(e) => setImage(e.dataTransfer.files[0])}
              className={css.DropArea}
              overlay={<div className={css.Overlay} />}
            >
              Drop your image here or&nbsp;
              <UI.Button variant="text" onClick={browseImages}>browse</UI.Button>
            </UI.DropArea>
          )}
      </div>
      <UI.ValidationError className={css.Error}>{errors.imageData}</UI.ValidationError>

      <input
        ref={imageFileRef}
        type="file"
        className={css.ImageFile}
        onChange={(e) => {
          if (e.target.files) {
            setImage(e.target.files[0]);
          }

          e.target.value = '';
        }}
      />
    </div>
  );
}
