import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from './Reducers/AuthReducer';
import categoryReducer from './Reducers/CategoryReducer';
import cityReducer from './Reducers/CityReducer';
import tipListReducer from './Reducers/TipListReducer';
import tipReducer from './Reducers/TipReducer';
import userReducer from './Reducers/UserReducer';
import testimonialReducer from './Reducers/TestimonialReducer';
import venueReducer from './Reducers/VenueReducer';
import countryReducer from './Reducers/CountryReducer';

export const store = configureStore({
  reducer: {
    users: userReducer,
    auth: authReducer,
    categories: categoryReducer,
    tipLists: tipListReducer,
    tips: tipReducer,
    countries: countryReducer,
    cities: cityReducer,
    venues: venueReducer,
    testimonials: testimonialReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
