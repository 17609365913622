import Tip from './Tip';
import TipList from './TipList';
import User from './User';
import CityCard from './CityCard';

export default {
  Tip,
  TipList,
  User,
  CityCard,
};
