import { CityModel } from 'Models/CityModel';
import { SearchModel } from 'Models/SearchModel';
import { TipListModel } from 'Models/TipListModel';
import { TipModel } from 'Models/TipModel';
import { UserModel } from 'Models/UserModel';
import { VenueModel } from 'Models/VenueModel';
import LocationServices from './LocationServices';
import ServiceUtils from './ServiceUtils';
import TipListService from './TipListService';
import TipService from './TipService';
import UserService from './UserService';

async function get(query: string): Promise<SearchModel|null> {
  if (query.length < 3) {
    return null;
  }

  try {
    const { data: result } = await ServiceUtils.http.get<SearchModel>(`/search/${query}`);

    return {
      cities: result.cities.map((city) => LocationServices.City.parse(city)) as CityModel[],
      tips: result.tips.map((tip) => TipService.parse(tip)) as TipModel[],
      tipLists: result.tipLists.map((tipList) => TipListService.parse(tipList)) as TipListModel[],
      users: result.users.map((user) => UserService.parse(user)) as UserModel[],
      venues: result.venues.map(
        (venue) => LocationServices.Venue.parse(venue),
      ) as VenueModel[],
    };
  } catch (err) {
    return null;
  }
}

export default {
  get,
};
