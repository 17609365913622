import { UserModel } from 'Models/UserModel';
import Services from 'Services';
import { AppDispatch } from 'Store';
import { authUpdated, signedOut } from 'Store/Reducers/AuthReducer';

/**
 * Sign in.
 * @param dispatch Dispatch function.
 * @param username Username.
 * @param password Password.
 */
const signIn = async (
  username: string,
  password: string,
): Promise<UserModel | null> => {
  const user = await Services.Auth.signIn(username, password);
  return user;
};

/**
 * Sign out.
 */
const signOut = (dispatch: AppDispatch) => {
  Services.Auth.signOut();
  dispatch(signedOut())
}

/**
 * Sign up.
 * @param args Sign up args.
 */
async function signUp(...args: Parameters<typeof Services.Auth.signUp>): Promise<void> {
  await Services.Auth.signUp(...args);
}

/**
 * Get authenticated user.
 * @param dispatch Dispatch function.
 */
const getAuthedUser = async (dispatch: AppDispatch): Promise<void> => {
  const user = await Services.Auth.getAuthedUser();

  dispatch(authUpdated(user ?? null));
};

export default {
  signIn,
  signOut,
  signUp,
  getAuthedUser,
};
