import React from 'react';
import ReactDOM from 'react-dom';
import MiscUtils from 'Utils/MiscUtils';

import css from './Modal.module.css';

type ModalProps = React.PropsWithChildren<{
  open: boolean,
  transitionTimeMs?: number;
  padded?: boolean;
}>;

export default function Modal({
  children,
  open,
  transitionTimeMs = 300,
  padded = true,
}: ModalProps): JSX.Element|null {
  let container = document.querySelector<HTMLDivElement>(`.${css.Container}`);
  const selfRef = React.useRef<HTMLDivElement>(null);
  const currTransition = React.useRef<number>();
  const initialStyle = React.useRef<React.CSSProperties>({
    transition: `opacity ${transitionTimeMs}ms`,
    opacity: open ? '1' : '0',
  });
  const [show, setShow] = React.useState(open);

  if (!container) {
    container = document.createElement('div');
    container.className = css.Container;
    document.body.append(container);
  }

  React.useEffect(() => {
    // Prevent scrolling while modals are showing.
    if (container) {
      const visible = container.children.length > 0;
      const { style, clientWidth } = document.body;
      const { innerWidth } = window;

      style.marginRight = visible ? `${innerWidth - clientWidth}px` : '';
      style.overflow = visible ? 'hidden' : '';
    }
  }, [show, container]);

  React.useEffect(() => {
    // Update transition time if prop changes.
    if (selfRef.current) {
      selfRef.current.style.transition = `opacity ${transitionTimeMs}ms`;
    }
  }, [transitionTimeMs]);

  React.useEffect(() => {
    // Animate modal.
    (async () => {
      currTransition.current = new Date().getTime();
      const thisTransition = currTransition.current;

      if (open) {
        setShow(true);
        await MiscUtils.sleep(5);
      }

      if (!selfRef.current) {
        return;
      }

      const { style } = selfRef.current;

      if (open) {
        if (thisTransition !== currTransition.current) { return; }

        style.opacity = '1';
      } else {
        style.opacity = '0';
        await MiscUtils.sleep(transitionTimeMs);
        if (thisTransition !== currTransition.current) { return; }

        setShow(false);
      }
    })();
  }, [open, transitionTimeMs]);

  const modal = (
    <div
      className={css.BackDrop}
      style={{
        ...initialStyle.current,
        padding: padded ? 10 : 0,
      }}
      ref={selfRef}
    >
      {children}
    </div>
  );

  return show ? ReactDOM.createPortal(modal, container) : null;
}
