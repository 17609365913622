import UI from 'Components/UI';
import React from 'react';
import MiscUtils from 'Utils/MiscUtils';
import css from './ValidationError.module.css';

type ValidationErrorProps = React.PropsWithChildren<{
  className?: string;
  transitionTimeMs?: number;
}>;

function getChildKeys(children: React.ReactNode): string {
  return (React.Children.toArray(children) as JSX.Element[])
    .map(({ key }) => key ?? 'pure-text')
    .join(',');
}

export default function ValidationError({
  className,
  children,
  transitionTimeMs = 150,
}: ValidationErrorProps): JSX.Element {
  const nextMessage = React.Children.toArray(children) as JSX.Element[];
  const nextId = getChildKeys(children);
  const [message, setMessage] = React.useState(nextMessage);
  const id = React.useRef(nextId);
  const [expanded, setExpanded] = React.useState(nextMessage.length > 0);

  React.useEffect(() => {
    if (nextId !== id.current) {
      id.current = nextId;

      (async () => {
        if (nextId === '') {
          setExpanded(false);
          await MiscUtils.sleep(transitionTimeMs);
          setMessage(nextMessage);
        } else {
          setMessage(nextMessage);
          await MiscUtils.sleep(5);
          setExpanded(true);
        }
      })();
    }
  });

  return (
    <UI.Collapsible
      expanded={expanded}
      className={[css.Container, className ?? ''].join(' ')}
      transitionTimeMs={transitionTimeMs}
    >
      {message}
    </UI.Collapsible>
  );
}
