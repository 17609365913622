import React from 'react';
import { Link } from 'react-router-dom';
import globalCss from 'Global.module.css';
import RouteConfig from 'RouteConfig';
import facebookIcon from 'Assets/Icons/facebook.svg';
import instagramIcon from 'Assets/Icons/instagram.svg';
import linkedinIcon from 'Assets/Icons/linkedin.svg';
import twitterIcon from 'Assets/Icons/twitter.svg';
import css from './Footer.module.css';

export default function Footer(): JSX.Element {
  return (
    <footer className={css.Container}>
      <div className={[globalCss.Content, css.Content].join(' ')}>
        <h4>WorldLobby</h4>

        <ul className={css.SiteLinks}>
          <li><Link to={RouteConfig.home.path}>FAQ</Link></li>
          <li><Link to={RouteConfig.home.path}>Who we are</Link></li>
          <li><Link to={RouteConfig.home.path}>Contact us</Link></li>
          <li><Link to={RouteConfig.home.path}>Report abuse</Link></li>
        </ul>

        <div className={css.Info}>
          <span>Copyright © 2020 WorldLobby. All rights reserved.</span>

          <ul className={css.SiteLinks}>
            <li><Link to={RouteConfig.home.path}>Privacy policy</Link></li>
            <li><Link to={RouteConfig.home.path}>Terms of use</Link></li>
            <li><Link to={RouteConfig.home.path}>Cookie policy</Link></li>
            <li><Link to={RouteConfig.home.path}>Site map</Link></li>
          </ul>

          <nav className={css.SocialLinks}>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <img src={facebookIcon} alt="Facebook" />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <img src={instagramIcon} alt="Instagram" />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <img src={twitterIcon} alt="Twitter" />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <img src={linkedinIcon} alt="LinkedIn" />
            </a>
          </nav>
        </div>
      </div>
    </footer>
  );
}
