import AddPhoto from './AddPhoto';
import BackArrow from './BackArrow';
import Star from './Star';
import Chevron from './Chevron';
import Close from './Close';
import Remove from './Remove';
import Edit from './Edit';
import Email from './Email';
import Facebook from './Facebook';
import Web from './Web';
import Google from './Google';
import Drafts from './Drafts';
import Tip from './Tip';
import Location from './Location';
import Money from './Money';
import Phone from './Phone';
import Search from './Search';
import Category from './Category';
import AddToTipList from './AddToTipList';
import Following from './Following';
import Followers from './Followers';
import Menu from './Menu';
import Plus from './Plus';
import Check from './Check';
import Move from './Move';
import Report from './Report';

export default {
  Drafts,
  Menu,
  Email,
  Facebook,
  Google,
  AddPhoto,
  BackArrow,
  Close,
  Tip,
  Search,
  Following,
  Followers,
  Category,
  Star,
  Chevron,
  AddToTipList,
  Location,
  Money,
  Phone,
  Web,
  Edit,
  Remove,
  Plus,
  Check,
  Move,
  Report,
};
