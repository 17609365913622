import { TipCategoryModel } from 'Models/TipCategoryModel';
import { RootState } from 'Store';

function get(id: string): (state: RootState) => TipCategoryModel | null {
  return (state: RootState) => state.categories[id] ?? null;
}

function list(...ids: string[]): (state: RootState) => TipCategoryModel[] {
  return (state: RootState) => ids
    .map((id) => state.categories[id])
    .filter((category) => category !== undefined);
}

function all(state: RootState): TipCategoryModel[] {
  return Object.values(state.categories);
}

export default {
  get,
  list,
  all,
};
