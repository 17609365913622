import React from 'react';

import css from './MultiLineField.module.css';

type MultiLineFieldProps = {
  label?: string;
  maxHeight?: number;
  forwardRef: React.ForwardedRef<HTMLTextAreaElement>;
} & React.DetailedHTMLProps<
React.TextareaHTMLAttributes<HTMLTextAreaElement>,
HTMLTextAreaElement
>;

function MultiLineField({
  label,
  className,
  onFocus,
  onBlur,
  maxHeight = 300,
  forwardRef,
  ...props
}: MultiLineFieldProps): JSX.Element {
  const [hasFocus, setHasFocus] = React.useState(false);
  const selfRef = React.useRef<HTMLDivElement>(null);
  const textAreaRef = React.useRef<HTMLTextAreaElement | null>(null);
  const { value, placeholder } = props;

  // Change size of text area on input.
  React.useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = '';
      textAreaRef.current.style.paddingBottom = '';

      if (textAreaRef.current.scrollHeight - textAreaRef.current.clientHeight > 0) {
        textAreaRef.current.style.paddingBottom = '6px';
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      }
    }
  }, [value]);

  return (
    <div
      className={[
        css.Container,
        className,
        css.Container,
        hasFocus ? css.HasFocus : '',
        `${value ?? ''}${placeholder ?? ''}`.length > 0 ? css.HasValue : '',
      ].join(' ')}
      style={{ maxHeight }}
      ref={selfRef}
    >
      {label && (<span className={css.Label}>{label}</span>)}

      <textarea
        {...props}
        className={css.TextArea}
        onFocus={(e) => {
          setHasFocus(true);

          if (onFocus) {
            onFocus(e);
          }
        }}
        onBlur={(e) => {
          setHasFocus(false);

          if (onBlur) {
            onBlur(e);
          }
        }}
        ref={(ref) => {
          textAreaRef.current = ref;

          if (typeof forwardRef === 'function') {
            forwardRef(ref);
          } else if (forwardRef) {
            /* eslint-disable */
            forwardRef.current = ref;
            /* eslint-enable */
          }
        }}
      />
    </div>
  );
}

// eslint-disable-next-line react/display-name
export default React.forwardRef(
  (props: Omit<MultiLineFieldProps, 'forwardRef'>, ref: React.ForwardedRef<HTMLTextAreaElement>) => (
    <MultiLineField {...props} forwardRef={ref} />
  ),
);
