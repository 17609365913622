import React from 'react';
import MiscUtils from 'Utils/MiscUtils';

import css from './Collapsible.module.css';

type CollapsibleProps = React.PropsWithChildren<{
  expanded: boolean,
  className?: string,
  expandedClassName?: string,
  transitionTimeMs?: number,
  transitions?: string[],
  forwardRef: React.ForwardedRef<HTMLSpanElement>,
}>;

function Collapsible({
  expanded,
  children,
  className,
  expandedClassName,
  transitionTimeMs = 300,
  transitions = [],
  forwardRef,
}: CollapsibleProps): JSX.Element {
  const selfRef = React.useRef<HTMLSpanElement | null>(null);

  // Initial render.
  React.useEffect(() => {
    if (!selfRef.current) {
      return;
    }

    selfRef.current.style.overflow = 'hidden';
    selfRef.current.style.height = '0';
  }, []);

  // Expanded has changed.
  React.useEffect(() => {
    const ref = selfRef.current;

    if (!ref) {
      return () => {};
    }

    let transitioning = true;
    const nextHeight = ref.scrollHeight;

    (async () => {
      if (!expanded) {
        ref.style.height = `${nextHeight}px`;
        ref.style.overflow = 'hidden';
      }

      await MiscUtils.sleep(5);

      if (!transitioning) {
        return;
      }

      ref.style.height = expanded ? `${nextHeight}px` : '0';

      if (expanded) {
        await MiscUtils.sleep(transitionTimeMs);

        if (!transitioning) {
          return;
        }

        ref.style.height = '';
        ref.style.overflow = '';
      }
    })();

    return () => { transitioning = false; };
  }, [expanded, transitionTimeMs]);

  return (
    <span
      className={[
        css.Container,
        className ?? '',
        expanded && expandedClassName ? expandedClassName : '',
      ].join(' ')}
      ref={(ref) => {
        selfRef.current = ref;

        if (typeof forwardRef === 'function') {
          forwardRef(ref);
        } else if (forwardRef) {
          /* eslint-disable */
          forwardRef.current = ref;
          /* eslint-enable */
        }
      }}
      style={{
        transition: [...transitions, 'height']
          .map((transition) => `${transition} ${transitionTimeMs}ms`)
          .join(', '),
      }}
    >
      {children}
    </span>
  );
}

// eslint-disable-next-line react/display-name
export default React.forwardRef(
  (props: Omit<CollapsibleProps, 'forwardRef'>, ref: React.ForwardedRef<HTMLSpanElement>) => (
    <Collapsible {...props} forwardRef={ref} />
  ),
);
