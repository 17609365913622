import { CityModel } from 'Models/CityModel';
import { RootState } from 'Store';
import CountrySelectors from './CountrySelectors';

function get(id: string): (state: RootState) => CityModel | null {
  return (state: RootState) => {
    const city = state.cities[id] ?? null;

    if (!city) {
      return city;
    }

    return {
      ...city,
      country: CountrySelectors.get(city.country?.id ?? '')(state) ?? city.country,
    };
  };
}

function list(...ids: string[]): (state: RootState) => CityModel[] {
  return (state: RootState) => {
    const cities = ids.map((id) => state.cities[id]).filter((city) => city !== undefined);
    const countryIds: string[] = [];

    cities.forEach((city) => {
      if (city.country?.id) {
        countryIds.push(city.country.id);
      }
    });

    const countries = CountrySelectors.list(...countryIds)(state);

    return cities.map((city) => ({
      ...city,
      country: countries.find((country) => country.id === city.country?.id) ?? city.country,
    }));
  };
}

export default {
  get,
  list,
};
