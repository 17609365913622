import Icons from "Components/Icons";
import React from "react";
import { Link } from "react-router-dom";
import RouteConfig from "RouteConfig";
import UI from "Components/UI";
import commonCss from "../SignInSignUpDialog.module.css";
import { NotificationContext } from "App";
import Hooks from "Hooks";
import AuthActions from "Store/Actions/AuthActions";

type SignUpProps = {
  onClose: () => void;
  goToSignIn: () => void;
};

type Data = {
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  emailRegistered: boolean;
};

export default function SignUp({
  onClose,
  goToSignIn,
}: SignUpProps): JSX.Element {
  const [data, errors, change, valid] = Hooks.useChange<Data>(
    {
      firstName: "",
      lastName: "",
      username: "",
      password: "",
      emailRegistered: false,
    },
    (nextData) => ({
      firstName:
        nextData.firstName.trim().length === 0
          ? "Please enter your first name"
          : undefined,
      lastName:
        nextData.lastName.trim().length === 0
          ? "Please enter your last name"
          : undefined,
      username: !nextData.username.match(/^.+@.+\..+$/)
        ? "Invalid E-mail address"
        : undefined,
      password:
        !nextData.password.match(/(?=.*\d)(?=.*[A-Z])(?=.*[a-z\w])/) ||
        nextData.password.length < 8 ||
        nextData.password.length > 24
          ? "Password must be 8 - 24 characters long and contain at least one number and one upper case letter."
          : undefined,
      emailRegistered: nextData.emailRegistered
        ? "E-mail address is already registered"
        : undefined,
    })
  );
  const [pending, setPending] = React.useState(false);
  const showNotification = React.useContext(NotificationContext);

  const submit = React.useCallback(
    async (nextData: Data) => {
      setPending(true);

      try {
        if (!valid()) {
          return;
        }

        await AuthActions.signUp({
          firstName: nextData.firstName,
          lastName: nextData.lastName,
          username: nextData.username,
          password: nextData.password,
        });

        showNotification({
          message: (
            <>
              Your account has been created!
              <br />
              Please check your&nbsp;
              <strong>E-mail</strong>
              &nbsp;to verify your account.
            </>
          ),
          timeoutMs: 7000,
        });

        onClose();
      } catch (err) {
        valid({ emailRegistered: true });
      } finally {
        setPending(false);
      }
    },
    [onClose, valid, showNotification]
  );

  return (
    <section className={commonCss.Container}>
      <div className={commonCss.Header}>
        <h5>Sign up</h5>

        <UI.Button
          variant="text"
          onClick={onClose}
          className={commonCss.CloseButton}
        >
          <Icons.Close />
        </UI.Button>
      </div>

      <div className={commonCss.Content}>
        <p className={commonCss.Description}>
          You'll need to create a free account before you can create your own
          lists and tips or follow other user's lists.
        </p>
        <div className={commonCss.Options}>
          <form
            className={commonCss.Form}
            noValidate
            onSubmit={(e) => {
              e.preventDefault();

              if (!pending) {
                submit(data);
              }
            }}
          >
            <div className={commonCss.Input}>
              <UI.TextField
                label="First name"
                value={data.firstName}
                onChange={(e) => change({ firstName: e.target.value })}
              />
              <UI.ValidationError>{errors.firstName}</UI.ValidationError>
            </div>

            <div className={commonCss.Input}>
              <UI.TextField
                label="Last name"
                value={data.lastName}
                onChange={(e) => change({ lastName: e.target.value })}
              />
              <UI.ValidationError>{errors.lastName}</UI.ValidationError>
            </div>

            <div className={commonCss.Input}>
              <UI.TextField
                type="email"
                label="E-mail"
                value={data.username}
                onChange={(e) =>
                  change({
                    username: e.target.value,
                    emailRegistered: false,
                  })
                }
              />
              <UI.ValidationError>
                {errors.username ?? errors.emailRegistered}
              </UI.ValidationError>
            </div>

            <div className={commonCss.Input}>
              <UI.TextField
                type="password"
                label="Password"
                value={data.password}
                onChange={(e) => change({ password: e.target.value })}
              />
              <UI.ValidationError>{errors.password}</UI.ValidationError>
            </div>

            <UI.Button color="secondary">
              {pending ? "Signing up..." : "Sign up"}
            </UI.Button>
          </form>
          <UI.Button
            variant="outline"
            color="secondary"
            className={commonCss.OptionsButton}
            onClick={() => {
              // TODO: Google sign up
            }}
          >
            <Icons.Google className={commonCss.OptionsIcon} />
            Sign up with Google
          </UI.Button>

          <UI.Button
            variant="outline"
            color="secondary"
            className={commonCss.OptionsButton}
            onClick={() => {
              // TODO: Facebook sign up
            }}
          >
            <Icons.Facebook className={commonCss.OptionsIcon} />
            Sign up with Facebook
          </UI.Button>
        </div>

        <div className={[commonCss.Navigation, commonCss.Bold].join(" ")}>
          Already have an account?&nbsp;
          <UI.Button variant="text" onClick={goToSignIn}>
            Sign in
          </UI.Button>
        </div>

        <Link
          to={RouteConfig.termsOfService.path}
          className={commonCss.ToC}
          onClick={onClose}
        >
          Terms of Service
        </Link>
      </div>
    </section>
  );
}
