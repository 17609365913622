import { UserModel } from 'Models/UserModel';
import Services from 'Services';
import { AppDispatch } from 'Store';
import { userUpdated } from 'Store/Reducers/UserReducer';

/**
 * Get a user.
 * @param dispatch Dispatch function.
 * @param id User identifier.
 */
async function get(dispatch: AppDispatch, id: string): Promise<UserModel | null> {
  const user = await Services.User.get(id);

  if (user) {
    dispatch(userUpdated([user]));
  }

  return user;
}

/**
 * List users.
 * @param dispatch Dispatch function.
 * @param args Listing args.
 */
async function list(
  dispatch: AppDispatch,
  ...args: Parameters<typeof Services.User.list>
): Promise<[UserModel[], number]> {
  const [users, total] = await Services.User.list(...args);

  dispatch(userUpdated(users));

  return [users, total];
}

/**
 * Update user.
 * @param dispatch Dispatch function.
 * @param args Update args.
 */
async function update(
  dispatch: AppDispatch,
  ...args: Parameters<typeof Services.User.update>
): Promise<UserModel | null> {
  const user = await Services.User.update(...args);

  if (user) {
    dispatch(userUpdated([user]));
  }

  return user;
}

export default {
  get,
  list,
  update,
};
