import { generatePath } from 'react-router-dom';

const paths = {
  home: '/home',
  about: '/about',
  city: '/cities/:cityId',
  country: '/countries/:countryId',
  place: '/places/:placeId',
  profile: '/profiles/:userId',
  tipList: '/tipLists/:tipListId',
  tip: '/tip/:tipId',
  termsOfService: '#',
  verifySignUp: '/verify/:code',
  venue: '/venues/:venueId',
};

export default {
  home: {
    path: paths.home,
  },
  about: {
    path: paths.about,
  },
  city: {
    path: paths.city,
    generate: (cityId: string): string => generatePath(paths.city, { cityId }),
  },
  profile: {
    path: paths.profile,
    generate: (userId: string): string => generatePath(paths.profile, { userId }),
  },
  tipList: {
    path: paths.tipList,
    generate: (tipListId: string, query?: Partial<{ tipId: string }>): string => {
      const path = generatePath(paths.tipList, { tipListId });
      const params = new URLSearchParams(query);

      return `${path}${Object.keys(query ?? {}).length > 0 ? `?${params.toString()}` : ''}`;
    },
  },
  termsOfService: {
    path: paths.termsOfService,
  },
  verifySignUp: {
    path: paths.verifySignUp,
    generate: (code: string): string => generatePath(paths.verifySignUp, { code }),
  },
};
