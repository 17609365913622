import React from 'react';

export default function Star({ className }: { className?: string }): JSX.Element {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 30 28"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M29.1663 11.09L18.9805 10.2117L14.9997 0.833374L11.0188 10.2259L0.833008 11.09L8.56801 17.7909L6.24467 27.75L14.9997 22.4659L23.7547 27.75L21.4455 17.7909L29.1663 11.09ZM14.9997 19.8167L9.67301 23.0325L11.0897 16.9692L6.38634 12.8892L12.5913 12.3509L14.9997 6.64171L17.4222 12.365L23.6272 12.9034L18.9238 16.9834L20.3405 23.0467L14.9997 19.8167Z" />
    </svg>
  );
}
