import { UserModel } from 'Models/UserModel';
import { RootState } from 'Store';
import UserSelectors from './UserSelectors';

function get(state: RootState): UserModel | null {
  return UserSelectors.get(state.auth.userId ?? '')(state) ?? null;
}

export default {
  get,
};
