import MainSearch from "Components/MainSearch";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import globalCss from "Global.module.css";
import RouteConfig from "RouteConfig";
import accountIcon from "Assets/Icons/account.svg";
import Icons from "Components/Icons";
import { ModalControllerContext } from "App";
import UI from "Components/UI";
import { useSelector } from "react-redux";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import AuthActions from "Store/Actions/AuthActions";
import TipSelectors from "Store/Selectors/TipSelectors";
import TipActions from "Store/Actions/TipActions";
import { useAppDispatch } from "Store/hooks";
import css from "./Header.module.css";

export default function Header(): JSX.Element {
  const {
    tipDialog,
    tipListDialog,
    signInDialog,
    signUpDialog,
    editProfileDialog,
  } = React.useContext(ModalControllerContext);
  const dispatch = useAppDispatch();
  const auth = useSelector(AuthSelectors.get);
  const authId = auth?.id;
  const tips = useSelector(TipSelectors.drafts);
  const tipCount = tips.length;
  const location = useLocation();
  const showSearch =
    location.pathname !== RouteConfig.home.path && location.pathname !== "/";
  const showMyProfile = location.pathname.split("/")[2] !== auth?.id

  // Sign out.
  const signOut = React.useCallback(
    async () => AuthActions.signOut(dispatch),
    [dispatch]
  );

  // Load tip drafts.
  React.useEffect(() => {
    if (!authId) {
      return;
    }

    TipActions.list(dispatch, {
      limit: 100,
      filters: { userIds: [authId], draft: true },
    });
  }, [dispatch, authId]);

  return (
    <header className={css.Container}>
      <div className={[globalCss.Content, css.Content].join(" ")}>
        <Link to={RouteConfig.home.path} className={css.Logo}>
          WorldLobby
        </Link>

        {showSearch && (
          <article className={css.Search}>
            <MainSearch
              placeholder="Choose another destination"
              className={css.SearchInput}
              wrapperClassName={css.SearchInputWrapper}
            />
          </article>
        )}

        <nav className={css.Menu}>
          <Link to={RouteConfig.about.path}>About</Link>

          {auth ? (
            <>
              <UI.ContextMenu
                align="right"
                button={<UI.Button variant="outline">Create...</UI.Button>}
              >
                <ul className={css.ContextOptions}>
                  <li>
                    <UI.Button
                      variant="text"
                      className={css.CreateButton}
                      onClick={() => tipListDialog()}
                    >
                      <Icons.AddToTipList />
                      New list
                    </UI.Button>
                  </li>

                  <li>
                    <UI.Button
                      variant="text"
                      className={css.CreateButton}
                      onClick={() => tipDialog()}
                    >
                      <Icons.Tip />

                      <span>New tip</span>
                    </UI.Button>
                  </li>
                </ul>
              </UI.ContextMenu>

              {tipCount === 0 ? (
                <Icons.Drafts />
              ) : (
                <UI.ContextMenu
                  align="right"
                  className={css.ContextDrafts}
                  button={
                    <UI.Button variant="text" className={css.DraftButton}>
                      <Icons.Drafts />
                      <span>{tipCount > 99 ? "99+" : tipCount}</span>
                    </UI.Button>
                  }
                >
                  <strong className={css.DraftTitle}>Drafts</strong>

                  <ul className={css.DraftList}>
                    {tips.map((tip) => (
                      <li key={tip.id}>
                        <UI.Button
                          title="Click to edit"
                          variant="text"
                          color="third"
                          onClick={() => tipDialog(tip)}
                          className={css.TipDraftButton}
                        >
                          <span>{tip.title ?? "Untitled tip"}</span>

                          {tip.venue && (
                            <span className={css.VenueName}>
                              {` · ${tip.venue.name}`}
                            </span>
                          )}
                        </UI.Button>
                      </li>
                    ))}
                  </ul>
                </UI.ContextMenu>
              )}

              <UI.ContextMenu
                button={
                  <img
                    src={auth.profileImage.thumb ?? accountIcon}
                    alt=""
                    className={css.Account}
                  />
                }
              >
                <ul className={css.ContextOptions}>
                  <li>
                    <strong>{`${auth.firstName} ${auth.lastName}`}</strong>
                  </li>

                  {showMyProfile && (<li>
                    <Link
                      to={RouteConfig.profile.generate(auth.id)}
                      className={css.AccountButton}
                    >
                      My profile
                    </Link>
                  </li>)}

                  <li
                    onClick={() => editProfileDialog()}
                    className={css.EditAccountButton}
                  >
                    Edit profile
                  </li>

                  <li>
                    <UI.Button
                      variant="text"
                      onClick={signOut}
                      className={css.SignOut}
                    >
                      Log out
                    </UI.Button>
                  </li>
                </ul>
              </UI.ContextMenu>
            </>
          ) : (
            <>
              <UI.Button
                variant="text"
                color="secondary"
                onClick={() => signInDialog()}
              >
                Log in
              </UI.Button>

              <UI.Button variant="outline" onClick={() => signUpDialog()}>
                Create account
              </UI.Button>
            </>
          )}
        </nav>
      </div>
    </header>
  );
}
