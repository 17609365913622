import React from 'react';

export default function Tip({ className }: { className?: string }) : JSX.Element {
  return (
    <svg
      width="16"
      height="20"
      viewBox="4 2 16 22"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M12.0001 3C11.5401 3 11.0701 3.04 10.6001 3.14C7.84007 3.67 5.64007 5.9 5.12007 8.66C4.64007 11.27 5.60007 13.67 7.34007 15.22C7.77007 15.6 8.00007 16.13 8.00007 16.69V19C8.00007 20.1 8.90007 21 10.0001 21H10.2801C10.6301 21.6 11.2601 22 12.0001 22C12.7401 22 13.3801 21.6 13.7201 21H14.0001C15.1001 21 16.0001 20.1 16.0001 19V16.69C16.0001 16.14 16.2201 15.6 16.6401 15.23C18.0901 13.95 19.0001 12.08 19.0001 10C19.0001 6.13 15.8701 3 12.0001 3ZM14.0001 19H10.0001V18H14.0001V19ZM14.0001 17H10.0001V16H14.0001V17ZM12.5001 11.41V14H11.5001V11.41L9.67007 9.59L10.3801 8.88L12.0001 10.5L13.6201 8.88L14.3301 9.59L12.5001 11.41Z" />
    </svg>
  );
}
