import Icons from 'Components/Icons';
import React from 'react';
import UI from '..';
import Button from '../Button';
import css from './Notifications.module.css';

export type MessageType = 'neutral'|'alert'|'error';

export type NotificationProps = {
  type: MessageType;
  timeoutMs: number;
  transitionTimeMs: number;
  message: React.ReactNode;
};

function Notification({
  type,
  timeoutMs,
  transitionTimeMs,
  message,
}: NotificationProps): JSX.Element|null {
  const [expanded, setExpanded] = React.useState(false);
  const selfRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    setExpanded(true);

    setTimeout(async () => {
      setExpanded(false);
    }, timeoutMs + transitionTimeMs);
  }, [timeoutMs, transitionTimeMs]);

  return (
    <UI.Collapsible
      expandedClassName={css.Expanded}
      expanded={expanded}
      transitionTimeMs={transitionTimeMs}
      className={css.Notification}
    >
      <article
        className={[
          css.Wrapper,
          {
            neutral: css.Neutral,
            alert: css.Alert,
            error: css.Error,
          }[type],
        ].join(' ')}
        style={{
          opacity: expanded ? 1 : 0,
          transition: `opacity ${transitionTimeMs}ms`,
        }}
        ref={selfRef}
      >
        <Button variant="text" className={css.Close} onClick={() => setExpanded(false)}>
          <Icons.Close />
        </Button>
        {message}
      </article>
    </UI.Collapsible>
  );
}

export default function useNotifications(): [
  JSX.Element,
  (props: Partial<NotificationProps>) => void,
] {
  const [notifications, setNotifications] = React.useState<JSX.Element[]>([]);

  const showNotification = React.useCallback(({
    type = 'neutral',
    timeoutMs = 3000,
    transitionTimeMs = 400,
    message,
  }: Partial<NotificationProps>) => {
    const notification = (
      <Notification
        key={`.${new Date().getTime()}`}
        type={type}
        timeoutMs={timeoutMs}
        transitionTimeMs={transitionTimeMs}
        message={message}
      />
    );

    setNotifications((current) => [...current, notification]);

    setTimeout(() => {
      setNotifications(
        (current) => current.filter((nextNotification) => nextNotification !== notification),
      );
    }, timeoutMs + (transitionTimeMs * 2));
  }, []);

  const container = (
    <div className={css.Container}>
      {notifications}
    </div>
  );

  return [container, showNotification];
}
