import { Data } from 'Dialogs/TipDialog/Types';
import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

function useChange<T extends Record<string, unknown>, U = Partial<{ [key in keyof T]: string }>>(
  initialState: T,
  validator?: (state: T) => U,
): [
    T,
    U,
    (nextData: Partial<T> | React.SetStateAction<T>) => void,
    (current?: Partial<T>, reportErrors?: boolean) => boolean,
  ] {
  const [data, setData] = React.useState<T>(initialState);
  const [errors, setErrors] = React.useState<U>({} as U);

  const change = React.useCallback((nextData: Partial<T> | React.SetStateAction<T>) => {
    if (typeof nextData === 'function') {
      setData(nextData);
    }

    setData((current) => ({ ...current, ...nextData }));
    const nextErrors: U = {} as U;

    Object.keys(nextData).forEach((key) => {
      nextErrors[key as keyof U] = undefined as unknown as U[keyof U];
    });

    setErrors((current) => ({ ...current, ...nextErrors }));
  }, [setData, setErrors]);

  const valid = React.useCallback((current?: Partial<T>, reportErrors = true) => {
    if (!validator) {
      return true;
    }

    const nextErrors = validator({ ...data, ...current });

    if (reportErrors) {
      setErrors(nextErrors);
    }

    return Object.values(nextErrors).filter((value) => value !== undefined).length === 0;
  }, [data, validator]);

  return [data, errors, change, valid];
}

function useSearch<Params extends { [K in keyof Params]?: string }>(): Params {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const result: Params = {} as Params;

  params.forEach((val, key) => {
    result[key as keyof Params] = val as Params[keyof Params];
  });

  return result;
}

function usePreviousState<T>(data: T) {
  const previousDataRef = useRef<T>();
  useEffect(() => {
    if (previousDataRef.current === undefined){
      previousDataRef.current = data;
    }
  }, [data]);
  return  previousDataRef.current
}

export default {
  usePreviousState,
  useChange,
  useSearch,
};
