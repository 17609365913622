import UI from 'Components/UI';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import MiscUtils from 'Utils/MiscUtils';
import css from './ProgMenu.module.css';

type ProgMenuProps = React.PropsWithChildren<{
  className?: string;
  align?: 'left' | 'right';
  vAlign?: 'top' | 'bottom'
  progToggle?: boolean | null;
  setProgToggle?: (toggle: boolean) => void;
  top: number,
  left: number,
}>;

export default function ProgMenu({
  className,
  align = 'right',
  vAlign = 'top',
  children,
  progToggle = null,
  setProgToggle,
  top,
  left,
}: ProgMenuProps): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const selfRef = React.useRef<HTMLSpanElement>(null);
  const contentRef = React.useRef<HTMLSpanElement>(null);

  if (contentRef.current) {
    contentRef.current.style.position = 'absolute';
  }

  let wrapper = document.querySelector<HTMLDivElement>(`.${css.ContextMenuWrapper}`);

  if (!wrapper) {
    wrapper = document.createElement('div');
    wrapper.className = css.ContextMenuWrapper;
    document.body.append(wrapper);
  }

  const toggle = React.useCallback(() => {
    setOpen((current) => !current);

    if (!(top && left && contentRef.current)) {
      return;
    }

    const contentRect = contentRef.current.getBoundingClientRect();

    contentRef.current.style.top = `${top + 40}px`;
    contentRef.current.style.left = `
    ${left - (align === 'right' ? contentRect.width - 32 : 0)}px`;
  }, [top, left, contentRef, align]);

  useEffect(() => {
    if (progToggle === true) {
      toggle();
      if (setProgToggle) {
        setProgToggle(false);
      }
    }
  }, [progToggle, setProgToggle, toggle, open]);

  const scroll = React.useCallback((e: Event) => {
    if (!contentRef.current || contentRef.current.contains(e.target as Node)) {
      return;
    }

    setOpen(false);
  }, []);

  const resize = React.useCallback(() => {
    setOpen(false);
  }, []);

  // Check that the context menu is not outside the window.
  React.useEffect(() => {
    let ticking = true;

    const tick = async () => {
      if (!(open && contentRef.current)) {
        return;
      }

      const { clientHeight, offsetTop } = contentRef.current;

      if (clientHeight + offsetTop > window.innerHeight) {
        // Context menu is outside of the viewport.
        contentRef.current.style.top = `${window.innerHeight - clientHeight}px`;
      }

      if (ticking) {
        await MiscUtils.sleep(100);
        tick();
      }
    };

    tick();

    return () => {
      ticking = false;
    };
  }, [open]);

  // Bind resize.
  React.useEffect(() => {
    window.addEventListener('resize', resize, true);

    return () => {
      window.removeEventListener('resize', resize, true);
    };
  }, [resize]);

  // Bind scroll.
  React.useEffect(() => {
    document.addEventListener('scroll', scroll, true);

    return () => {
      document.removeEventListener('scroll', scroll, true);
    };
  }, [scroll]);

  // Bind click outside.
  React.useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      if (open && selfRef.current && !selfRef.current.contains(e.target as Node)
      && contentRef.current && !contentRef.current.contains(e.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('click', onClickOutside);

    return () => {
      document.removeEventListener('click', onClickOutside);
    };
  }, [open]);

  const content = ReactDOM.createPortal(
    <UI.Collapsible
      ref={contentRef}
      expanded={open}
      className={css.Menu}
      expandedClassName={css.Expanded}
      transitions={['opacity']}
      transitionTimeMs={200}
    >
      <div
        className={css.Wrapper}
        // onClick={() => setExpanded(false)}
        onKeyDown={() => {}}
        role="button"
        tabIndex={-1}
      >
        {children}
      </div>
    </UI.Collapsible>,
    wrapper,
  );

  return (
    <span
      className={[
        css.Container,
        className ?? '',
        align === 'left' ? css.LeftAlign : css.RightAlign,
        vAlign === 'top' ? css.TopAlign : css.BottomAlign,
      ].join(' ')}
      ref={selfRef}
    >
      {content}
    </span>
  );
}
