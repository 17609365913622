/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserModel } from 'Models/UserModel';
import { authUpdated, signedIn } from './AuthReducer';
import {
  tipListRemoved, tipListFollowChanged, tipRemoved, tipBorrowedChanged,
} from './commonActions';

type UserState = { [id: string]: UserModel };
const initialState: UserState = {};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userUpdated: (state, action: PayloadAction<UserModel[]>) => {
      action.payload.forEach((user) => {
        state[user.id] = user;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signedIn, (state, action) => {
      if (!action.payload) return;
      state[action.payload.id] = action.payload;
    });
    builder.addCase(authUpdated, (state, action) => {
      if (!action.payload) return;
      state[action.payload.id] = action.payload;
    });
    builder.addCase(tipListRemoved, (state, action) => {
      state[action.payload.user.id].tipListCount -= 1;
    });
    builder.addCase(tipListFollowChanged, (state, action) => {
      state[action.payload.user.id].followedTipListCount
      += action.payload.following ? 1 : -1;
    });
    builder.addCase(tipRemoved, (state, action) => {
      state[action.payload.user.id].tipCount -= 1;
    });
    builder.addCase(tipBorrowedChanged, (state, action) => {
      state[action.payload.user.id].borrowedTipCount += action.payload.borrowing ? 1 : -1;
    });
  },
});

export const { userUpdated } = userSlice.actions;
export default userSlice.reducer;
