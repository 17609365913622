import Icons from 'Components/Icons';
import { useModal } from 'Components/UI/Modal';
import React from 'react';
import accountIcon from 'Assets/Icons/account.svg';
import MiscUtils from 'Utils/MiscUtils';
import { CityModel } from 'Models/CityModel';
import { UserModel } from 'Models/UserModel';
import UI from 'Components/UI';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import UserActions from 'Store/Actions/UserActions';
import AuthSelectors from 'Store/Selectors/AuthSelectors';
import Hooks from 'Hooks';
import { NotificationContext } from 'App';
import css from './EditProfileDialog.module.css';

type Data = {
  firstName: string;
  lastName: string;
  description: string;
  coverImage?: string;
  profileImage?: string;
  city?: CityModel;
};

type EditProfileDialogProps = {
  onClose: () => void;
};

export default function EditProfileDialog({ onClose }: EditProfileDialogProps): JSX.Element {
  const dispatch = useDispatch();
  const auth = useSelector(AuthSelectors.get) as UserModel;
  const [confirmDialog, showConfirmDialog, hideConfirmDialog] = useModal(
    <UI.ConfirmDialog
      message="Are you sure you want to discard your changes?"
      confirmLabel="Yes"
      cancelLabel="No"
      onCancel={() => hideConfirmDialog()}
      onConfirm={() => {
        hideConfirmDialog();
        onClose();
      }}
    />,
  );
  const showNotification = React.useContext(NotificationContext);

  const [data, errors, change, valid] = Hooks.useChange<Data>(
    {
      firstName: auth.firstName,
      lastName: auth.lastName,
      description: auth.description ?? '',
      coverImage: undefined,
      profileImage: undefined,
      city: undefined,
    },
    (nextData) => ({
      firstName: nextData.firstName.trim().length === 0 ? 'Please enter your first name' : undefined,
      lastName: nextData.lastName.trim().length === 0 ? 'Please enter your last name' : undefined,
    }),
  );
  const [pending, setPending] = React.useState(false);

  const coverImageFileRef = React.useRef<HTMLInputElement>(null);
  const profileImageFileRef = React.useRef<HTMLInputElement>(null);

  // Set cover image.
  const setCoverImage = React.useCallback(async (file: File) => {
    try {
      change({ coverImage: await MiscUtils.imageToBase64(file) });
    } catch (err) {
      showNotification({ message: 'Invalid image', type: 'error' });
    }
  }, [change, showNotification]);

  // Set profile image.
  const setProfileImage = React.useCallback(async (file: File) => {
    try {
      change({ profileImage: await MiscUtils.imageToBase64(file) });
    } catch (err) {
      showNotification({ message: 'Invalid image', type: 'error' });
    }
  }, [change, showNotification]);

  // Browse cover image.
  const browseCoverImage = React.useCallback(() => {
    if (coverImageFileRef.current) {
      coverImageFileRef.current.click();
    }
  }, []);

  // Browse profile image.
  const browseProfileImage = React.useCallback(() => {
    if (profileImageFileRef.current) {
      profileImageFileRef.current.click();
    }
  }, []);

  
  const previousData = Hooks.usePreviousState(data)

  // Cancel edit.
  const cancel = React.useCallback(() => {
    if (previousData === undefined || shallowEqual(previousData, data)) {
      onClose();
      return
    }
    showConfirmDialog()
  }, [data, onClose, previousData, showConfirmDialog]);

  // Save changes.
  const save = React.useCallback(async (nextData: Data) => {
    if (pending) {
      return;
    }

    try {
      setPending(true);

      if (!valid()) {
        return;
      }

      UserActions.update(
        dispatch,
        auth.id,
        nextData.firstName,
        nextData.lastName,
        nextData.description,
        nextData.coverImage,
        nextData.profileImage,
        nextData.city?.id,
      );

      onClose();
    } finally {
      setPending(false);
    }
  }, [onClose, dispatch, auth.id, pending, valid]);

  const imageDropOverlay = (
    <div className={css.ImageDropOverlay}>
      Drop to upload
    </div>
  );

  return (
    <section className={css.Container}>
      {confirmDialog}

      <div className={css.Header}>
        <h5>Edit my profile</h5>

        <UI.Button variant="text" onClick={cancel} className={css.CloseButton}>
          <Icons.Close />
        </UI.Button>
      </div>

      <div>
        <div className={css.Images}>
          <input
            ref={coverImageFileRef}
            type="file"
            className={css.ImageFile}
            onChange={(e) => {
              if (e.target.files) {
                setCoverImage(e.target.files[0]);
              }

              e.target.value = '';
            }}
          />
          <UI.DropArea
            className={css.CoverImage}
            onDrop={(e) => setCoverImage(e.dataTransfer.files[0])}
            overlay={imageDropOverlay}
          >
            {(data.coverImage || auth.coverImage.medium) && (
              <img
                src={data.coverImage ?? auth.coverImage.medium}
                alt="Cover"
              />
            )}

            <UI.Button variant="text" className={css.AddPhotoButton} onClick={browseCoverImage}>
              <Icons.AddPhoto />
            </UI.Button>
          </UI.DropArea>

          <input
            ref={profileImageFileRef}
            type="file"
            className={css.ImageFile}
            onChange={(e) => {
              if (e.target.files) {
                setProfileImage(e.target.files[0]);
              }

              e.target.value = '';
            }}
          />
          <UI.DropArea
            className={css.ProfileImage}
            onDrop={(e) => setProfileImage(e.dataTransfer.files[0])}
            overlay={imageDropOverlay}
          >
            <img
              src={data.profileImage ?? auth.profileImage.small ?? accountIcon}
              alt="Profile"
            />

            <UI.Button variant="text" className={css.AddPhotoButton} onClick={browseProfileImage}>
              <Icons.AddPhoto />
            </UI.Button>
          </UI.DropArea>
        </div>

        <div className={css.Info}>
          <div className={css.Input}>
            <UI.TextField
              className={css.Input}
              label="First name"
              value={data.firstName}
              onChange={(e) => change({ firstName: e.target.value })}
            />
            <UI.ValidationError>{errors.firstName}</UI.ValidationError>
          </div>

          <div className={css.Input}>
            <UI.TextField
              className={css.Input}
              label="Last name"
              value={data.lastName}
              onChange={(e) => change({ lastName: e.target.value })}
            />
            <UI.ValidationError>{errors.lastName}</UI.ValidationError>
          </div>

          <UI.LocationSearch.City
            value={data.city}
            onChange={(value) => change({ city: value })}
            className={css.Input}
          />

          <div className={css.FullSpan}>
            <UI.MultiLineField
              className={css.Input}
              label="Biography"
              value={data.description}
              onChange={(e) => change({ description: e.target.value })}
            />
            <span className={css.CharCounter}>{`${data.description.length}/240`}</span>
          </div>
        </div>

        <div className={css.Actions}>
          <UI.Button variant="outline" onClick={cancel}>
            Cancel
          </UI.Button>

          <UI.Button type="submit" onClick={() => save(data)}>
            {pending ? 'Saving...' : 'Save'}
          </UI.Button>
        </div>
      </div>
    </section>
  );
}
