import React from 'react';

import css from './TextField.module.css';

type TextFieldProps = {
  label?: string;
  forwardRef?: React.ForwardedRef<HTMLInputElement>;
  icon?: JSX.Element;
} & React.DetailedHTMLProps<
React.InputHTMLAttributes<HTMLInputElement>,
HTMLInputElement
>;

function TextField({
  className,
  label,
  forwardRef,
  onChange,
  onFocus,
  onBlur,
  icon,
  ...props
}: TextFieldProps): JSX.Element {
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [hasFocus, setHasFocus] = React.useState(false);
  const { placeholder, value } = props;

  const click = React.useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div
      onClick={click}
      onKeyDown={() => {}}
      role="textbox"
      tabIndex={-1}
      className={[
        css.Container,
        className,
        label ? css.HasLabel : '',
        `${value ?? ''}${placeholder ?? ''}`.length > 0 ? css.HasValue : '',
        hasFocus ? css.HasFocus : '',
      ].join(' ')}
    >
      {icon && (<span className={css.Icon}>{icon}</span>)}

      <input
        {...props}
        className={css.Input}
        onChange={onChange}
        onFocus={(e) => {
          setHasFocus(true);

          if (onFocus) {
            onFocus(e);
          }
        }}
        onBlur={(e) => {
          setHasFocus(false);

          if (onBlur) {
            onBlur(e);
          }
        }}
        ref={(ref) => {
          inputRef.current = ref;

          if (typeof forwardRef === 'function') {
            forwardRef(ref);
          } else if (forwardRef) {
            /* eslint-disable */
            forwardRef.current = ref;
            /* eslint-enable */
          }
        }}
      />

      {label && (<span className={css.Label}>{label}</span>)}
    </div>
  );
}

// eslint-disable-next-line react/display-name
export default React.forwardRef(
  (props: Omit<TextFieldProps, 'forwardRef'>, ref: React.ForwardedRef<HTMLInputElement>) => (
    <TextField {...props} forwardRef={ref as React.RefObject<HTMLInputElement>} />
  ),
);
