/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CityModel } from 'Models/CityModel';
import { tipListRemoved } from './commonActions';

type CityState = { [id: string]: CityModel };
const initialState: CityState = {};

const citySlice = createSlice({
  name: 'city',
  initialState,
  reducers: {
    cityUpdated: (state, action: PayloadAction<CityModel[]>) => {
      action.payload.forEach((city) => {
        state[city.id] = city;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(tipListRemoved, (state, action) => {
      state[action.payload.city.id].tipListCount -= 1;
    });
  },
});

export const { cityUpdated } = citySlice.actions;
export default citySlice.reducer;
