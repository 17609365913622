import React, { useState } from 'react';
import Icons from 'Components/Icons';
import { TipModel } from 'Models/TipModel';
import { Link } from 'react-router-dom';
import RouteConfig from 'RouteConfig';
import MiscUtils from 'Utils/MiscUtils';
import AddToTipListContextMenu from 'Components/AddToTipListContextMenu';
import { ModalControllerContext, NotificationContext, UserLocationContext } from 'App';
import { useSelector } from 'react-redux';
import UI from 'Components/UI';
import AuthSelectors from 'Store/Selectors/AuthSelectors';
import AddToTipLists from 'Components/AddToTipLists/AddToTipLists';
import MoveToList from 'Components/MoveToList';
import css from './Tip.module.css';
import commonCss from './Common.module.css';

type TipProps = {
  value: TipModel;
  className?: string;
  size?: 'small' | 'medium';
  onNavigate?: () => void;
  onRemoveTipFromTipList?: (tip: TipModel) => void;
};

export default function Tip({
  value,
  className,
  size = 'medium',
  onNavigate,
  onRemoveTipFromTipList,
}: TipProps): JSX.Element {
  const { tipDialog } = React.useContext(ModalControllerContext);
  const auth = useSelector(AuthSelectors.get);
  const [expanded, setExpanded] = React.useState(false);
  const userLocation = React.useContext(UserLocationContext);
  const distance = MiscUtils.getDistance(
    { lat: value.venue?.latitude, lng: value.venue?.longitude },
    userLocation ?? { lat: undefined, lng: undefined },
  );
  const showOptions = onRemoveTipFromTipList || auth?.id === value.user.id;
  const [tipListAdd, setTipListAdd] = useState(false);
  const [tipListMove, setTipListMove] = useState(false);
  const anchorRef = React.useRef<HTMLSpanElement>(null);
  const showNotification = React.useContext(NotificationContext);

  return (
    <div
      className={[
        commonCss.Container,
        className ?? '',
        { small: commonCss.Small, medium: commonCss.Medium }[size],
      ].join(' ')}
    >
      <div className={commonCss.Header}>
        <img
          src={value.image.medium ?? value.venue?.image.medium}
          alt=""
          className={commonCss.Image}
        />

        {auth?.id === value.user.id && (
          <span className={[css.Status, { 0: css.Draft, 2: css.Published }[value.status]].join(' ')}>
            {{
              0: 'Draft',
              2: 'Published',
            }[value.status]}
          </span>
        )}

        {showOptions && (
          <span ref={anchorRef}>
            <UI.ContextMenu
              className={commonCss.Options}
              button={(
                <UI.Button variant="text" className={commonCss.OptionsButton}>
                  <Icons.Menu className={commonCss.OptionsIcon} />
                </UI.Button>
            )}
            >
              {onRemoveTipFromTipList && (
                <UI.Button
                  variant="text"
                  className={[commonCss.RemoveButton, commonCss.Option].join(' ')}
                  onClick={() => {
                    if (onRemoveTipFromTipList) {
                      onRemoveTipFromTipList(value);
                    }
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {auth?.id === value.user.id
                      ? <Icons.Remove className={commonCss.Icon} />
                      : <Icons.Star className={commonCss.Icon} />}
                    {auth?.id === value.user.id
                      ? <span>Remove from list</span> : <span>Unborrow from list</span>}
                  </div>
                </UI.Button>
              )}

              {auth?.id === value.user.id && (
                <UI.Button
                  variant="text"
                  className={[commonCss.EditButton, commonCss.Option].join(' ')}
                  onClick={() => tipDialog(value)}
                >
                  <Icons.Edit className={commonCss.Icon} />

                  <span>Edit tip</span>
                </UI.Button>
              )}

              <UI.Button
                variant="text"
                className={[commonCss.EditButton, commonCss.Option].join(' ')}
                onClick={() => setTipListMove((prev) => !prev)}
              >
                <Icons.Move className={commonCss.Icon} />

                <span>Move tip to another list</span>
              </UI.Button>

              <UI.Button
                variant="text"
                className={[commonCss.EditButton, commonCss.Option].join(' ')}
                onClick={() => setTipListAdd((prev) => !prev)}
              >
                <Icons.AddToTipList className={commonCss.Icon} />

                <span>Save tip to additional lists</span>
              </UI.Button>

              {auth?.id !== value.user.id && (
              <UI.Button
                variant="text"
                className={[commonCss.EditButton, commonCss.Option].join(' ')}
                onClick={() => showNotification({
                  message: (
                    <>
                      <div>
                        <strong>
                          {`"${value.title}" `}
                          has been reported
                        </strong>
                      </div>
                      A WorldLobby editor will inspect it and
                      take the appropriate action.
                    </>
                  ),
                  type: 'error',
                })}
              >
                <Icons.Report className={commonCss.Icon} />

                <span>Report tip</span>
              </UI.Button>
              )}

            </UI.ContextMenu>

          </span>
        )}
      </div>

      <AddToTipLists
        open={tipListAdd}
        setOpen={setTipListAdd}
        top={anchorRef.current?.firstElementChild?.getBoundingClientRect().top ?? 0}
        left={anchorRef.current?.firstElementChild?.getBoundingClientRect().left ?? 0}
        tip={value}
      />

      <MoveToList
        open={tipListMove}
        setOpen={setTipListMove}
        top={anchorRef.current?.firstElementChild?.getBoundingClientRect().top ?? 0}
        left={anchorRef.current?.firstElementChild?.getBoundingClientRect().left ?? 0}
        tip={value}
        onRemoveTipFromTipList={onRemoveTipFromTipList}
      />

      <div className={commonCss.Main}>
        <div className={commonCss.Actions}>
          {value.category?.icon !== undefined && (
            <Icons.Category icon={value.category.icon} circle />
          )}

          <AddToTipListContextMenu tip={value} buttonClassName={commonCss.Action} align="left" />
        </div>

        <div className={commonCss.Content}>
          <h2 className={commonCss.Title}>{value.title}</h2>

          <h3 className={commonCss.Location}>
            {value.venue?.name}
          </h3>

          <Link
            to={RouteConfig.profile.generate(value.user.id)}
            className={commonCss.User}
            onClick={onNavigate}
          >
            {[value.user.firstName, value.user.lastName].join(' ')}
          </Link>

          <p className={commonCss.Description}>{value.description}</p>

          <UI.Button
            variant="text"
            onClick={() => setExpanded((current) => !current)}
            className={[css.MoreInfo, expanded ? css.Expanded : ''].join(' ')}
          >
            More Information
            <Icons.Chevron direction={expanded ? 'up' : 'down'} className={css.Icon} />
          </UI.Button>

          <UI.Collapsible expanded={expanded}>
            <ul className={css.VenueDetails}>
              {value.venue?.address && (
                <li>
                  <Icons.Location className={css.Icon} />
                  {`${distance ? `${distance} · ` : ''}${value.venue.address}`}
                </li>
              )}

              {typeof value.venue?.priceLevel === 'number' && (
                <li>
                  <Icons.Money className={css.Icon} />
                  {[...Array(value.venue.priceLevel ?? 0)].map(() => '$')}
                </li>
              )}

              {value.venue?.phone && (
                <li>
                  <Icons.Phone className={css.Icon} />
                  {value.venue.phone}
                </li>
              )}

              {value.venue?.website && (
                <li>
                  <Icons.Web className={css.Icon} />
                  <a href={value.venue.website} target="_blank" rel="noopener noreferrer">
                    Website
                  </a>
                </li>
              )}
            </ul>
          </UI.Collapsible>
        </div>
      </div>
    </div>
  );
}
