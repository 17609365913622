import React from 'react';

export default function Plus({ className }: { className?: string }): JSX.Element {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M19.3334 11.3334H11.3334V19.3334H8.66675V11.3334H0.666748V8.66669H8.66675V0.666687H11.3334V8.66669H19.3334V11.3334Z" />
    </svg>
  );
}
