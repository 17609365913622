import UI from 'Components/UI';
import React from 'react';
import css from './SelectField.module.css';

export type SelectFieldProps = {
  label?: string;
  header?: (value: unknown) => JSX.Element;
  maxHeight?: number;
  icon?: JSX.Element;
  forwardRef?: React.ForwardedRef<HTMLSelectElement>;
} & React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;

function SelectField({
  label,
  className,
  children,
  tabIndex = 0,
  header,
  maxHeight = 300,
  icon,
  forwardRef,
  ...props
}: SelectFieldProps): JSX.Element {
  const selfRef = React.useRef<HTMLDivElement>(null);
  const selectRef = React.useRef<HTMLSelectElement | null>(null);
  const [expanded, setExpanded] = React.useState(false);
  const [hasFocus, setHasFocus] = React.useState(false);
  const { value, placeholder } = props;
  const options = (React.Children.toArray(children) as JSX.Element[]);
  const [hoveredIdx, setHoveredIdx] = React.useState(
    Math.max(options.findIndex((option) => option.props.value === value), 0),
  );

  const toggle = React.useCallback(() => {
    setExpanded((current) => !current);
    setHoveredIdx(Math.max(options.findIndex((option) => option.props.value === value), 0));
  }, [options, value]);

  const focus = React.useCallback(() => {
    setHasFocus(true);

    if (selectRef?.current) {
      selectRef.current.focus();
    }
  }, [selectRef]);

  const blur = React.useCallback(() => {
    setHasFocus(false);
    setExpanded(false);

    if (selectRef?.current) {
      selectRef.current.blur();
    }
  }, [selectRef]);

  const change = React.useCallback((nextValue) => {
    if (selectRef?.current) {
      selectRef.current.value = nextValue;
      selectRef.current.dispatchEvent(new Event('change', { bubbles: true }));
    }
  }, [selectRef]);

  const keyDown = React.useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
    let nextIdx = hoveredIdx;

    switch (e.key) {
      case 'ArrowUp':
        nextIdx = Math.max(hoveredIdx - 1, 0);
        setHoveredIdx(nextIdx);
        change(options[nextIdx].props.value);
        break;
      case 'ArrowDown':
        nextIdx = Math.min(hoveredIdx + 1, options.length - 1);
        setHoveredIdx(nextIdx);
        change(options[nextIdx].props.value);
        break;
      case ' ':
        toggle();
        break;
      case 'Escape':
        setExpanded(false);
        break;
      case 'Enter':
        if (expanded) {
          change(options[nextIdx].props.value);
        }

        toggle();
        break;
      default:
        break;
    }
  }, [options, change, toggle, hoveredIdx, expanded]);

  React.useEffect(() => {
    const focusIn = (e: FocusEvent) => {
      if (selfRef.current && !selfRef.current.contains(e.target as Node)) {
        blur();
      }
    };

    document.addEventListener('focusin', focusIn);
    document.addEventListener('click', focusIn);

    return () => {
      document.removeEventListener('focusin', focusIn);
      document.removeEventListener('click', focusIn);
    };
  }, [blur]);

  return (
    <div
      className={[
        css.Container,
        className ?? '',
        expanded ? css.Expanded : '',
        hasFocus ? css.HasFocus : '',
        `${(value ?? '').toString()}${placeholder ?? ''}`.length > 0 ? css.HasValue : '',
      ].join(' ')}
      tabIndex={tabIndex}
      onFocus={focus}
      onKeyDown={keyDown}
      onClick={toggle}
      role="combobox"
      aria-controls=""
      aria-expanded={expanded}
      ref={selfRef}
    >
      <select
        {...props}
        className={css.Select}
        ref={(ref) => {
          selectRef.current = ref;

          if (typeof forwardRef === 'function') {
            forwardRef(ref);
          } else if (forwardRef) {
            /* eslint-disable */
            forwardRef.current = ref;
            /* eslint-enable */
          }
        }}
      >
        {children}
      </select>

      {icon && (<span className={css.Icon}>{icon}</span>)}

      {label && (<span className={css.Label}>{label}</span>)}

      {header
        ? header(value)
        : (
          <div className={css.Header}>
            {value === undefined
              ? placeholder && (<span className={css.Placeholder}>{placeholder}</span>)
              : options.find((option) => option.props.value === value)?.props.children}
          </div>
        )}

      <UI.Collapsible
        expanded={expanded}
        transitionTimeMs={200}
      >
        {options.length > 0 && (
          <ol className={css.Options} style={{ maxHeight }}>
            {options.map((option, idx) => (
              <li
                key={option.key}
                className={[css.Option, hoveredIdx === idx ? css.Hovered : ''].join(' ')}
              >
                <span
                  onMouseOver={() => setHoveredIdx(idx)}
                  onClick={() => change(option.props.value)}
                  role="option"
                  tabIndex={-1}
                  aria-selected={option.props.value === value}
                  onKeyDown={() => {}}
                  onFocus={() => {}}
                >
                  {option.props.children}
                </span>
              </li>
            ))}
          </ol>
        )}
      </UI.Collapsible>
    </div>
  );
}

// eslint-disable-next-line react/display-name
export default React.forwardRef(
  (props: Omit<SelectFieldProps, 'forwardRef'>, ref: React.ForwardedRef<HTMLSelectElement>) => (
    <SelectField {...props} forwardRef={ref} />
  ),
);
