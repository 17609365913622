import { VenueModel } from 'Models/VenueModel';
import { RootState } from 'Store';

function get(id: string): (state: RootState) => VenueModel | null {
  return (state: RootState) => {
    const venue = state.venues[id] ?? null;

    if (!venue) {
      return venue;
    }

    return venue;
  };
}

function list(...ids: string[]): (state: RootState) => VenueModel[] {
  return (state: RootState) => {
    const venues = ids.map((id) => state.venues[id]).filter((venue) => venue !== undefined);

    return venues;
  };
}

export default {
  get,
  list,
};
