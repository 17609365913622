import React from 'react';
import { TipListModel } from 'Models/TipListModel';
import { Link } from 'react-router-dom';
import RouteConfig from 'RouteConfig';
import MiscUtils from 'Utils/MiscUtils';
import accountIcon from 'Assets/Icons/account.svg';
import commonCss from './Common.module.css';
import css from './CityCard.module.css';

type TipListProps = {
  value: TipListModel;
};

export default function CityCard({
  value,
}: TipListProps): JSX.Element {
  return (
    <div
      className={css.Container}
    >
      <Link to={RouteConfig.tipList.generate(value.id)}>
        <img
          src={value.image.medium ?? value.city?.image.medium}
          alt=""
          className={commonCss.Image}
        />

        <div className={css.Content}>

          <div className={css.User}>
            <img
              src={value.user.profileImage.thumb ?? accountIcon}
              alt=""
              className={commonCss.Avatar}
            />

            <h3 className={css.Name}>
              {[value.user.firstName, value.user.lastName].join(' ')}
            </h3>
          </div>
          <h1 className={css.Title}>{value.title}</h1>

          <h5 className={css.Info}>
            {MiscUtils.delimit(undefined, value.city?.fullName)}
          </h5>
          <h5 className={css.Info}>
            {`${value.tipCount} Tips • ${value.followerCount} followers`}
          </h5>
        </div>
      </Link>
    </div>
  );
}
