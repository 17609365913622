import { PaginationModel } from 'Models/PaginationModel';
import { TipModel, TipStatusEnum } from 'Models/TipModel';
import { UserModel } from 'Models/UserModel';
import { AppDispatch } from 'Store';
import { tipUpdated } from 'Store/Reducers/commonActions';
import MiscUtils from 'Utils/MiscUtils';
import LocationServices from './LocationServices';
import ServiceUtils from './ServiceUtils';
import TipCategoryService from './TipCategoryService';
import UserService from './UserService';

function parse(tip: TipModel | null): TipModel | null {
  if (!tip) {
    return null;
  }

  const nextTip: TipModel = {
    ...tip,
    createdAt: new Date(tip.createdAt),
    modifiedAt: new Date(tip.modifiedAt),
    venue: LocationServices.Venue.parse(tip.venue),
    user: UserService.parse(tip.user) as UserModel,
    category: TipCategoryService.parse(tip.category),
    image: MiscUtils.getImageModel(tip.imageId, tip.title),
    type: 'TIP',
  };

  return nextTip;
}

async function get(id: string): Promise<TipModel | null> {
  try {
    const { data: tip } = await ServiceUtils.http.get<TipModel>(`/tips/${id}`);

    return parse(tip);
  } catch (err) {
    return null;
  }
}

async function list(args: {
  limit?: number;
  offset?: number;
  filters?: {
    universeOfUserId?: string;
    borrowerUserIds?: string[];
    tipListIds?: string[];
    cityIds?: string[];
    userIds?: string[];
    draft?: boolean;
  };
  sort?: {
    tipListCount?: boolean;
    borrowerCount?: boolean;
  };
  withTipListId?: boolean;
} = {}): Promise<[TipModel[], number]> {
  const params = {
    sort: ServiceUtils.getSort(args.sort),
    ...ServiceUtils.getPagination(args.limit, args.offset),
    ...ServiceUtils.getFilters(args.filters),
    tipListId: args.withTipListId
  };

  const { data: { total, data: tips } } = await ServiceUtils.http.get<PaginationModel<TipModel>>(
    '/tips',
    { params },
  );

  return [tips.map((tip) => parse(tip)) as TipModel[], total];
}

async function create(
  title: string|null,
  description: string|null,
  venueId: string|null,
  imageData: string|null,
  categoryId: string|null,
  tipListIds: string[]|null,
  status: TipStatusEnum|null,
): Promise<TipModel|null> {
  const data = {
    title,
    description,
    venueId,
    imageData,
    categoryId,
    tipListIds,
    status,
  };

  try {
    const { data: tip } = await ServiceUtils.http.post<TipModel>(
      '/tips',
      status === TipStatusEnum.DRAFT
        ? data
        : MiscUtils.removeNullFields(data),
    );

    return parse(tip);
  } catch (err) {
    return null;
  }
}

async function update(
  tipId: string,
  title: string|null,
  description: string|null,
  venueId: string|null,
  imageData: string|null|undefined,
  categoryId: string|null,
  tipListIds: string[]|null,
  status: TipStatusEnum|null,
): Promise<TipModel|null> {
  try {
    const data = {
      title,
      description,
      venueId,
      imageData,
      categoryId,
      tipListIds,
      status,
    };

    if (imageData === undefined) {
      delete data.imageData;
    }

    const { data: tip } = await ServiceUtils.http.patch<TipModel>(
      `/tips/${tipId}`,
      status === TipStatusEnum.DRAFT
        ? data
        : MiscUtils.removeNullFields(data),
    );

    return parse(tip);
  } catch (err) {
    return null;
  }
}

async function remove(tipId: string): Promise<boolean> {
  try {
    await ServiceUtils.http.delete(`/tips/${tipId}`);

    return true;
  } catch (err) {
    return false;
  }
}

async function borrowUnborrow(tipId: string, borrowing: boolean): Promise<TipModel|null> {
  try {
    const { data: tip } = await ServiceUtils.http.patch<TipModel>(`/tips/${tipId}`, { borrowing });

    return parse(tip);
  } catch (err) {
    return null;
  }
}

function getLoadMoreFunc(
  callback: (data: [TipModel[], number]) => void|Promise<void>,
  dispatch: AppDispatch,
  args: Parameters<typeof list>[0],
): () => Promise<void> {
  const { limit = 1 } = { ...args };
  let offset = 0;
  let pendingReq: ReturnType<typeof list>|undefined;
  const items: TipModel[] = [];
  let total = 0;

  return async (): Promise<void> => {
    if (offset === -1) {
      return;
    }

    if (pendingReq) {
      return;
    }

    pendingReq = list({ ...args, offset });
    offset += 1;
    const [nextItems, nextTotal] = await pendingReq;
    total = nextTotal;

    dispatch(tipUpdated(nextItems));

    if (nextItems.length < limit) {
      offset = -1;
    }

    items.push(...nextItems);

    await callback([[...items], total]);
    pendingReq = undefined;
  };
}

export default {
  parse,
  get,
  list,
  create,
  update,
  remove,
  borrowUnborrow,
  getLoadMoreFunc,
};
