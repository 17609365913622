import React from 'react';

export default function Followers({ className }: { className?: string }): JSX.Element {
  return (
    <svg
      width="20"
      height="19"
      viewBox="-2 -6 31 31"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M19.3124 9.50004C21.0949 9.50004 22.5287 8.05338 22.5287 6.27087C22.5287 4.48837 21.0949 3.04171 19.3124 3.04171C17.5299 3.04171 16.0833 4.48837 16.0833 6.27087C16.0833 8.05338 17.5299 9.50004 19.3124 9.50004ZM9.62492 8.20837C11.7691 8.20837 13.487 6.47754 13.487 4.33337C13.487 2.18921 11.7691 0.458374 9.62492 0.458374C7.48075 0.458374 5.74992 2.18921 5.74992 4.33337C5.74992 6.47754 7.48075 8.20837 9.62492 8.20837ZM19.3124 12.0834C16.9487 12.0834 12.2083 13.2717 12.2083 15.6355V18.5417H26.4166V15.6355C26.4166 13.2717 21.6762 12.0834 19.3124 12.0834ZM9.62492 10.7917C6.61534 10.7917 0.583252 12.303 0.583252 15.3125V18.5417H9.62492V15.6355C9.62492 14.5375 10.0512 12.613 12.6862 11.1534C11.5624 10.9209 10.4774 10.7917 9.62492 10.7917Z" />
    </svg>
  );
}
