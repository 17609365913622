import { PaginationModel } from 'Models/PaginationModel';
import { TipCategoryModel } from 'Models/TipCategoryModel';
import nightLifeIcon from 'Assets/Icons/nightlife.svg';
import nightLifePin from 'Assets/Icons/CategoryPins/nightlife.svg';
import hotelsIcon from 'Assets/Icons/hotels.svg';
import hotelsPin from 'Assets/Icons/CategoryPins/hotels.svg';
import dontsIcon from 'Assets/Icons/donts.svg';
import dontsPin from 'Assets/Icons/CategoryPins/donts.svg';
import dosIcon from 'Assets/Icons/dos.svg';
import dosPin from 'Assets/Icons/CategoryPins/dos.svg';
import shoppingIcon from 'Assets/Icons/shopping.svg';
import shoppingPin from 'Assets/Icons/CategoryPins/shopping.svg';
import restaurantsIcon from 'Assets/Icons/restaurants.svg';
import restaurantsPin from 'Assets/Icons/CategoryPins/restaurants.svg';
import ServiceUtils from './ServiceUtils';

const iconMapping = {
  0: nightLifeIcon,
  1: restaurantsIcon,
  2: shoppingIcon,
  3: hotelsIcon,
  4: dosIcon,
  5: dontsIcon,
};

const pinMapping = {
  0: nightLifePin,
  1: restaurantsPin,
  2: shoppingPin,
  3: hotelsPin,
  4: dosPin,
  5: dontsPin,
};

function parse(tipCategory: TipCategoryModel | null): TipCategoryModel | null {
  if (!tipCategory) {
    return null;
  }

  const nextTipCategory: TipCategoryModel = {
    ...tipCategory,
    createdAt: new Date(tipCategory.createdAt),
    modifiedAt: new Date(tipCategory.modifiedAt),
    iconImage: iconMapping[tipCategory.icon] ?? null,
    iconPinImage: pinMapping[tipCategory.icon] ?? null,
  };

  return nextTipCategory;
}

async function list(): Promise<[TipCategoryModel[], number]> {
  const {
    data: { total, data: tipCategories },
  } = await ServiceUtils.http.get<PaginationModel<TipCategoryModel>>('/tips/categories');

  return [tipCategories.map((tipCategory) => parse(tipCategory)) as TipCategoryModel[], total];
}

async function get(id: string): Promise<TipCategoryModel|null> {
  try {
    const { data: tipCategory } = await ServiceUtils.http.get<TipCategoryModel>(`/tips/categories/${id}`);

    return parse(tipCategory);
  } catch (err) {
    return null;
  }
}

export default {
  parse,
  list,
  get,
};
