import React from 'react';
import Icons from 'Components/Icons';
import UI from 'Components/UI';
import { TipListModel } from 'Models/TipListModel';
import TipListSelectors from 'Store/Selectors/TipListSelectors';
import { useDispatch, useSelector } from 'react-redux';
import { ModalControllerContext } from 'App';
import { TipModel } from 'Models/TipModel';
import TipListActions from 'Store/Actions/TipListActions';
import { AppDispatch } from 'Store';
import { Data } from '../Types';
import css from './TipList.module.css';

async function loadAllTipLists(
  dispatch: AppDispatch,
  args: Parameters<typeof TipListActions.list>[1],
): Promise<TipListModel[]> {
  const [tipLists] = await TipListActions.list(
    dispatch,
    { ...args, limit: 100, offset: (args?.offset ?? 0) },
  );

  if (tipLists.length < 100) {
    return tipLists;
  }

  return [...tipLists, ...(await loadAllTipLists(
    dispatch,
    { ...args, offset: (args?.offset ?? 0) + 1 },
  ))];
}

type ListerProps = {
  title: string;
  tipLists: TipListModel[];
  selectedTipLists: TipListModel[];
  change: (data: Data) => void;
};

function Lister({
  title,
  tipLists,
  selectedTipLists,
  change,
}: ListerProps): JSX.Element | null {
  const add = React.useCallback((tipList: TipListModel) => {
    change({ tipLists: [...selectedTipLists, tipList] });
  }, [change, selectedTipLists]);

  const remove = React.useCallback((tipList: TipListModel) => {
    change({ tipLists: selectedTipLists.filter((nextTipList) => nextTipList.id !== tipList.id) });
  }, [change, selectedTipLists]);

  const toggle = React.useCallback((tipList) => {
    if (selectedTipLists.findIndex((nextTipList) => nextTipList.id === tipList.id) === -1) {
      add(tipList);
    } else {
      remove(tipList);
    }
  }, [selectedTipLists, add, remove]);

  if (tipLists.length === 0) {
    return null;
  }

  return (
    <ul className={css.Items}>
      <li className={css.Header}>{title}</li>

      {tipLists.map((tipList) => (
        <li key={tipList.id}>
          <UI.Button color="secondary" variant="text" onClick={() => toggle(tipList)}>
            {selectedTipLists.findIndex((nextTipList) => nextTipList.id === tipList.id) === -1
              ? (<Icons.Plus className={css.AddToTipListIcon} />)
              : (<Icons.Check className={[css.AddToTipListIcon, css.Selected].join(' ')} />)}

            {tipList.title}
          </UI.Button>
        </li>
      ))}
    </ul>
  );
}

type TipListsProps = {
  data: Data;
  userId?: string;
  tipId?: string;
  change: (data: React.SetStateAction<Data>) => void;
};

export default function TipLists({
  data,
  userId,
  tipId,
  change,
}: TipListsProps): JSX.Element {
  const dispatch = useDispatch();
  const { tipListDialog } = React.useContext(ModalControllerContext);
  const [cityTipListIds, setCityTipListIds] = React.useState<string[]>([]);
  const cityTipLists = useSelector(TipListSelectors.list(...cityTipListIds));
  const [otherTipListIds, setOtherTipListIds] = React.useState<string[]>([]);
  const otherTipLists = useSelector(TipListSelectors.list(...otherTipListIds));

  // City id changed, fetch available tip lists.
  React.useEffect(() => {
    let mounted = true;

    const cityId = data.venue?.cityId;

    (async () => {
      if (!userId) {
        setOtherTipListIds([]);
        return;
      }

      const nextOtherTipLists = await loadAllTipLists(
        dispatch,
        { filters: { '!cityIds': cityId ? [cityId] : undefined, userIds: [userId] } },
        // TODO: Order by proximity.
      );

      if (mounted) {
        setOtherTipListIds(nextOtherTipLists.map((tipList) => tipList.id));
      }
    })();

    (async () => {
      if (!(cityId && userId)) {
        setCityTipListIds([]);
        return;
      }

      const nextCityTipLists = await loadAllTipLists(
        dispatch,
        { filters: { cityIds: [cityId], userIds: [userId] } },
      );

      if (mounted) {
        setCityTipListIds(nextCityTipLists.map((tipList) => tipList.id));
      }
    })();

    return () => {
      mounted = false;
    };
  }, [dispatch, data.venue?.cityId, userId]);

  // Callback when a tip list is created inside this dialog.
  const onTipListCreate = React.useCallback((tipList: TipListModel) => {
    if (tipList.city.id === data.venue?.cityId) {
      setCityTipListIds((current) => [tipList.id, ...current]);
    } else {
      setOtherTipListIds((current) => [tipList.id, ...current]);
    }

    change((current) => ({ ...current, tipLists: [...(current.tipLists ?? []), tipList] }));
  }, [data.venue?.cityId, change]);

  // Tip id has changed
  React.useEffect(() => {
    change({ tipLists: [] });

    let mounted = true;

    if (!(tipId && userId)) {
      return () => {
        mounted = false;
      };
    }

    // Get tip lists.
    (async () => {
      const nextTipLists = await loadAllTipLists(dispatch, {
        filters: { userIds: [userId], tipId },
      });

      if (mounted) {
        change({ tipLists: nextTipLists });
      }
    })();

    return () => {
      mounted = false;
    };
  }, [tipId, dispatch, userId, change]);

  return (
    <div className={css.Container}>
      <strong>
        {`Add to list${(data.tipLists?.length ?? 0) > 0 ? ` (${data.tipLists?.length})` : ''}`}
      </strong>

      <div className={css.TipLists}>
        <Lister
          title={`My ${cityTipLists?.[0]?.city?.name} lists`}
          tipLists={cityTipLists}
          selectedTipLists={data.tipLists ?? []}
          change={change}
        />

        <Lister
          title="All my lists"
          tipLists={otherTipLists}
          selectedTipLists={data.tipLists ?? []}
          change={change}
        />

        <UI.Button
          variant="text"
          onClick={() => {
            tipListDialog(undefined, data as TipModel, onTipListCreate);
          }}
        >
          Create new list
        </UI.Button>
      </div>
    </div>
  );
}
