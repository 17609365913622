import React from 'react';
import { Link } from 'react-router-dom';
import { UserModel } from 'Models/UserModel';
import RouteConfig from 'RouteConfig';
import MiscUtils from 'Utils/MiscUtils';
import accountIcon from 'Assets/Icons/account.svg';
import css from './User.module.css';

type UserProps = {
  className?: string;
  user?: UserModel|null,
  size?: 'small'|'medium'|'large',
  onNavigate?: () => void;
  displayDescription?: boolean;
};

export default function User({
  user = null,
  className,
  onNavigate,
  size = 'medium',
  displayDescription: pDisplayDescription,
}: UserProps): JSX.Element|null {
  // Default values for displaying description.
  const displayDescription = pDisplayDescription === undefined
    ? {
      small: false,
      medium: false,
      large: true,
    }[size]
    : pDisplayDescription;

  if (!user) {
    return null;
  }

  const profileLink = RouteConfig.profile.generate(user.id);

  return (
    <article
      className={[
        css.Container,
        className ?? '',
        {
          small: css.Small,
          medium: css.Medium,
          large: css.Large,
        }[size],
      ].join(' ')}
    >
      <Link to={profileLink} className={css.Image} onClick={onNavigate}>
        <img
          src={user.profileImage.thumb ?? accountIcon}
          alt={MiscUtils.getFullName(user)}
        />
      </Link>

      <p className={css.Info}>
        <Link to={profileLink} className={css.Name} onClick={onNavigate}>
          {MiscUtils.getFullName(user)}
        </Link>

        <strong className={css.TipsAndLists}>
          {MiscUtils.getTipTipListCount(user)}
        </strong>

        <span className={css.MemberSince}>
          {`Member since ${user.createdAt.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}`}
        </span>

        {displayDescription && user.description && (
          <span className={css.Description}>{user.description}</span>
        )}
      </p>
    </article>
  );
}
