import Icons from 'Components/Icons';
import React from 'react';
import Cards from 'Components/Cards';
import { UserModel } from 'Models/UserModel';
import Services from 'Services';
import UI from 'Components/UI';
import { useDispatch, useSelector } from 'react-redux';
import AuthSelectors from 'Store/Selectors/AuthSelectors';
import TipListSelectors from 'Store/Selectors/TipListSelectors';
import css from './FollowedTipListsDialog.module.css';

type FollowedTipListsDialogProps = {
  onClose: () => void;
  user: UserModel;
};

export default function FollowedTipListsDialog({
  onClose,
  user,
}: FollowedTipListsDialogProps): JSX.Element {
  const dispatch = useDispatch();
  const auth = useSelector(AuthSelectors.get);
  const [tipListIds, setTipListIds] = React.useState<string[]>([]);
  const tipLists = useSelector(TipListSelectors.list(...tipListIds));
  const [total, setTotal] = React.useState(0);
  const myTipLists = auth && auth.id === user.id;
  const tipListsRef = React.useRef<HTMLDivElement>(null);
  const userId = user.id;

  // Load more function.
  const funcInitialized = React.useRef<number>(0);
  const loadMore = React.useMemo(
    () => {
      const now = new Date().getTime();
      funcInitialized.current = now;

      return Services.TipList.getLoadMoreFunc(
        ([nextTipLists, nextTotal]) => {
          if (funcInitialized.current === now) {
            setTipListIds(nextTipLists.map((tipList) => tipList.id));
            setTotal(nextTotal);
          }
        },
        dispatch,
        { filters: { followerUserIds: [userId] }, limit: 8 },
      );
    },
    [userId, dispatch],
  );

  // Initial load
  React.useEffect(() => {
    loadMore();

    const ref = tipListsRef.current;

    if (!ref) {
      return () => {};
    }

    // Bind scroll to load more at a certain threshold.
    const onScroll = async () => {
      if (ref.scrollHeight - ref.clientHeight - ref.scrollTop < 200) {
        loadMore();
      }
    };

    ref.addEventListener('scroll', onScroll);

    return () => ref.removeEventListener('scroll', onScroll);
  }, [loadMore]);

  return (
    <section className={css.Container}>
      <div className={css.Header}>
        <h5>
          {`Lists ${myTipLists ? 'you' : user.firstName} follow${myTipLists ? '' : 's'} (${total})`}
        </h5>

        <UI.Button variant="text" onClick={onClose} className={css.CloseButton}>
          <Icons.Close />
        </UI.Button>
      </div>

      <div className={css.Content} ref={tipListsRef}>
        <div className={css.TipLists}>
          {tipLists.length === 0 && (
            <span>No lists followed</span>
          )}

          {tipLists.map((tipList) => (
            <Cards.TipList
              key={tipList.id}
              className={css.TipList}
              size="small"
              value={tipList}
              onNavigate={onClose}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
