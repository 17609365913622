import React from 'react';

export default function Following({ className }: { className?: string }): JSX.Element {
  return (
    <svg
      width="30"
      height="24"
      viewBox="-4 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M14,10H2v2h12V10z M14,6H2v2h12V6z M2,16h8v-2H2V16z M21.5,11.5L23,13l-6.99,7l-4.51-4.5L13,14l3.01,3L21.5,11.5z" />
    </svg>
  );
}
