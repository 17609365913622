import { CityModel } from 'Models/CityModel';
import { PaginationModel } from 'Models/PaginationModel';
import { TipListModel } from 'Models/TipListModel';
import { UserModel } from 'Models/UserModel';
import { AppDispatch } from 'Store';
import { tipListUpdated } from 'Store/Reducers/commonActions';
import MiscUtils from 'Utils/MiscUtils';
import LocationServices from './LocationServices';
import ServiceUtils from './ServiceUtils';
import UserService from './UserService';

function parse(tipList: TipListModel | null): TipListModel | null {
  if (!tipList) {
    return null;
  }

  const nextTipList: TipListModel = {
    ...tipList,
    createdAt: new Date(tipList.createdAt),
    modifiedAt: new Date(tipList.modifiedAt),
    user: UserService.parse(tipList.user) as UserModel,
    city: LocationServices.City.parse(tipList.city) as CityModel,
    image: MiscUtils.getImageModel(tipList.imageId, tipList.title),
    type: 'TIP_LIST',
  };

  return nextTipList;
}

async function get(id: string): Promise<TipListModel|null> {
  try {
    const { data: tipList } = await ServiceUtils.http.get<TipListModel>(`/tips/lists/${id}`);

    return parse(tipList);
  } catch (err) {
    return null;
  }
}

async function list(args: {
  limit?: number;
  offset?: number;
  filters?: {
    universeOfUserId?: string;
    followerUserIds?: string[];
    countryIds?: string[];
    cityIds?: string[];
    '!cityIds'?: string[];
    userIds?: string[];
    tipId?: string;
    '!tipId'?: string;
  };
  sort?: {
    tipCount?: boolean;
    followerCount?: boolean;
  };
} = {}): Promise<[TipListModel[], number]> {
  const params = {
    sort: ServiceUtils.getSort(args.sort),
    ...ServiceUtils.getPagination(args.limit, args.offset),
    ...ServiceUtils.getFilters(args.filters),
  };

  const {
    data: { total, data: tipLists },
  } = await ServiceUtils.http.get<PaginationModel<TipListModel>>(
    '/tips/lists',
    { params },
  );

  return [tipLists.map((tipList) => parse(tipList)) as TipListModel[], total];
}

async function create(
  title: string | null,
  description: string | null,
  cityId: string | null,
): Promise<TipListModel> {
  try {
    const { data: tipList } = await ServiceUtils.http.post<TipListModel>(
      '/tips/lists',
      {
        title,
        description,
        cityId,
      },
    );

    return parse(tipList) as TipListModel;
  } catch ({ response: { status } }) {
    throw new Error(status);
  }
}

async function update(
  tipListId: string,
  title: string|null,
  description: string|null,
  cityId: string|null,
): Promise<TipListModel> {
  try {
    const { data: tipList } = await ServiceUtils.http.patch<TipListModel>(
      `tip_lists/${tipListId}`,
      MiscUtils.removeNullFields({
        title,
        description,
        cityId,
      }),
    );

    return parse(tipList) as TipListModel;
  } catch ({ response: { status } }) {
    throw new Error(status);
  }
}

async function remove(tipListId: string): Promise<boolean> {
  try {
    await ServiceUtils.http.delete(`/tips/lists/${tipListId}`);

    return true;
  } catch (err) {
    return false;
  }
}

async function addTips(tipListId: string, tipIds: string[]): Promise<void> {
  try {
    await ServiceUtils.http.post(
      `/tips/lists/${tipListId}`,
      { action: 0, tipIds },
    );
  } catch (err) {
    //
  }
}

async function removeTips(tipListId: string, tipIds: string[]): Promise<void> {
  try {
    await ServiceUtils.http.post(
      `/tips/lists/${tipListId}`,
      { action: 1, tipIds },
    );
  } catch (err) {
    //
  }
}

async function followUnfollow(tipListId: string, following: boolean): Promise<TipListModel | null> {
  try {
    const { data: tipList } = await ServiceUtils.http.patch<TipListModel>(
      `/tips/lists/${tipListId}`,
      { following },
    );

    return parse(tipList);
  } catch (err) {
    return null;
  }
}

function getLoadMoreFunc(
  callback: (data: [TipListModel[], number]) => void|Promise<void>,
  dispatch: AppDispatch,
  args: Parameters<typeof list>[0],
): () => Promise<void> {
  const { limit = 1 } = { ...args };
  let offset = 0;
  let pendingReq: ReturnType<typeof list> | undefined;
  const items: TipListModel[] = [];
  let total = 0;

  return async (): Promise<void> => {
    if (offset === -1) {
      return;
    }

    if (pendingReq) {
      return;
    }

    pendingReq = list({ ...args, offset });
    offset += 1;
    const [nextItems, nextTotal] = await pendingReq;
    total = nextTotal;

    dispatch(tipListUpdated(nextItems));

    if (nextItems.length < limit) {
      offset = -1;
    }

    items.push(...nextItems);

    await callback([[...items], total]);
    pendingReq = undefined;
  };
}

export default {
  parse,
  get,
  list,
  create,
  update,
  remove,
  addTips,
  removeTips,
  followUnfollow,
  getLoadMoreFunc,
};
