import React from "react";
import { Footer, Header } from "Partials";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import RouteConfig from "RouteConfig";
import useModalController from "ModalController";
import { useDispatch, useSelector } from "react-redux";
import AuthActions from "Store/Actions/AuthActions";
import AuthSelectors from "Store/Selectors/AuthSelectors";
import CategoryActions from "Store/Actions/CategoryActions";
import useNotifications from "Components/UI/Notifications";
import { NotificationProps } from "Components/UI/Notifications/Notifications";

const AboutView = React.lazy(() => import("Views/AboutView"));
const CityView = React.lazy(() => import("Views/CityView"));
const HomeView = React.lazy(() => import("Views/HomeView"));
const ProfileView = React.lazy(() => import("Views/ProfileView"));
const TipListView = React.lazy(() => import("Views/TipListView"));
const NotFoundView = React.lazy(() => import("Views/NotFoundView"));
const VerifySignUp = React.lazy(() => import("Views/VerifySignUp"));

export const NotificationContext = React.createContext<
  (props: Partial<NotificationProps>) => void
>(() => {});

export const ModalControllerContext = React.createContext<
  ReturnType<typeof useModalController>[1]
>({} as ReturnType<typeof useModalController>[1]);

export const UserLocationContext = React.createContext<
  | {
      lat: number;
      lng: number;
    }
  | undefined
>(undefined);

function App(): JSX.Element {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const auth = useSelector(AuthSelectors.get);
  const [userLocation, setUserLocation] =
    React.useState<{ lat: number; lng: number }>();
  const [modals, modalActions] = useModalController();
  const location = useLocation();
  const history = useHistory();
  const [notifications, showNotification] = useNotifications();

  // Fetch logged in user every 15 minutes.
  React.useEffect(() => {
    const repeat = async () => {
      await AuthActions.getAuthedUser(dispatch);

      setTimeout(repeat, 900000);
    };

    repeat();
  }, [dispatch]);

  // Get tip categories.
  React.useEffect(() => {
    CategoryActions.list(dispatch);
  }, [dispatch]);

  // Get location
  React.useEffect(() => {
    if (!navigator.geolocation) {
      return;
    }

    navigator.geolocation.getCurrentPosition((pos) => {
      setUserLocation({ lat: pos.coords.latitude, lng: pos.coords.longitude });
    });
  }, []);

  React.useEffect(() => {
    // Scroll to top upon navigation.
    window.scrollTo(0, 0);
  }, [pathname]);

  React.useEffect(() => {
    if (auth && location.pathname === "/") {
      history.push(RouteConfig.profile.generate(auth.id));
    }
  }, [auth, history, location.pathname]);

  return (
    <UserLocationContext.Provider value={userLocation}>
      <ModalControllerContext.Provider value={modalActions}>
        <NotificationContext.Provider value={showNotification}>
          {notifications}
          {modals}

          <Header />

          <div style={{ minHeight: "100vh" }}>
            <React.Suspense fallback={null}>
              <Switch>
                <Route exact path="/">
                  <HomeView />
                </Route>

                <Route exact path={RouteConfig.home.path}>
                  <HomeView />
                </Route>

                <Route exact path={RouteConfig.about.path}>
                  <AboutView />
                </Route>

                <Route exact path={RouteConfig.city.path}>
                  <CityView />
                </Route>

                <Route exact path={RouteConfig.profile.path}>
                  <ProfileView />
                </Route>

                <Route exact path={RouteConfig.tipList.path}>
                  <TipListView />
                </Route>

                <Route exact path={RouteConfig.verifySignUp.path}>
                  <VerifySignUp />
                </Route>

                <Route path="*">
                  <NotFoundView />
                </Route>
              </Switch>
            </React.Suspense>
          </div>

          <Footer />
        </NotificationContext.Provider>
      </ModalControllerContext.Provider>
    </UserLocationContext.Provider>
  );
}

export default App;
