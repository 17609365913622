import Icons from "Components/Icons";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import RouteConfig from "RouteConfig";
import UI from "Components/UI";
import commonCss from "../SignInSignUpDialog.module.css";
import Hooks from "Hooks";
import { useDispatch } from "react-redux";
import AuthActions from "Store/Actions/AuthActions";
import { signedIn } from "Store/Reducers/AuthReducer";

type Data = {
  username: string;
  password: string;
  invalidCredentials: boolean;
};

type SignInProps = {
  onClose: () => void;
  goToSignUp: () => void;
};

export default function SignIn({
  onClose,
  goToSignUp,
}: SignInProps): JSX.Element {
  const dispatch = useDispatch();
  const [pending, setPending] = React.useState(false);
  const [data, errors, change, valid] = Hooks.useChange<Data>(
    {
      username: "",
      password: "",
      invalidCredentials: false,
    },
    (nextData) => ({
      username:
        nextData.username.length === 0
          ? "Please enter yoru username"
          : undefined,
      password:
        nextData.password.length === 0
          ? "Please enter your password"
          : undefined,
      invalidCredentials: nextData.invalidCredentials
        ? "Invalid credentials"
        : undefined,
    })
  );

  const history = useHistory();

  const submit = React.useCallback(
    async (nextData: Data) => {
      setPending(true);

      try {
        if (!valid()) {
          return;
        }

        const nextUser = await AuthActions.signIn(
          nextData.username,
          nextData.password
        );
        if (!nextUser) {
          valid({ invalidCredentials: true });
          return;
        }
        dispatch(signedIn(nextUser));

        onClose();
        history.push(RouteConfig.profile.generate(nextUser.id));
      } catch (err) {
        //
      } finally {
        setPending(false);
      }
    },
    [onClose, dispatch, history, valid]
  );

  return (
    <section className={commonCss.Container}>
      <div className={commonCss.Header}>
        <h5>Sign in</h5>

        <UI.Button
          variant="text"
          onClick={onClose}
          className={commonCss.CloseButton}
        >
          <Icons.Close />
        </UI.Button>
      </div>

      <div className={commonCss.Content}>
        <p className={commonCss.Description}>
          Sign in to share lists and tips, personalize your homepage, and follow
          the lists that you love.
        </p>
        <form
          className={commonCss.Form}
          noValidate
          onSubmit={(e) => {
            e.preventDefault();

            if (!pending) {
              submit(data);
            }
          }}
        >
          <div className={commonCss.Input}>
            <UI.TextField
              type="email"
              label="E-mail"
              value={data.username}
              onChange={(e) =>
                change({
                  username: e.target.value,
                  invalidCredentials: false,
                })
              }
            />
            <UI.ValidationError>{errors.username}</UI.ValidationError>
          </div>

          <div className={commonCss.Input}>
            <UI.TextField
              type="password"
              label="Password"
              value={data.password}
              onChange={(e) =>
                change({
                  password: e.target.value,
                  invalidCredentials: false,
                })
              }
            />
            <UI.ValidationError>
              {errors.password ?? errors.invalidCredentials}
            </UI.ValidationError>
          </div>

          <UI.Button color="secondary">
            {pending ? "Signing in..." : "Sign in"}
          </UI.Button>
        </form>
        <div className={commonCss.Options}>
          <UI.Button
            variant="outline"
            color="secondary"
            className={commonCss.OptionsButton}
            onClick={() => {
              // TODO: Google sign in
            }}
          >
            <Icons.Google className={commonCss.OptionsIcon} />
            Sign in with Google
          </UI.Button>

          <UI.Button
            variant="outline"
            color="secondary"
            className={commonCss.OptionsButton}
            onClick={() => {
              // TODO: Facebook sign in
            }}
          >
            <Icons.Facebook className={commonCss.OptionsIcon} />
            Sign in with Facebook
          </UI.Button>
        </div>

        <div className={[commonCss.Navigation, commonCss.Bold].join(" ")}>
          Don&apos;t have an account yet?&nbsp;
          <UI.Button variant="text" onClick={goToSignUp}>
            Sign up
          </UI.Button>
        </div>

        <Link
          to={RouteConfig.termsOfService.path}
          className={commonCss.ToC}
          onClick={onClose}
        >
          Terms of Service
        </Link>
      </div>
    </section>
  );
}
