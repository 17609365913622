import React from 'react';

import css from './Category.module.css';

const mapping = {
  100: (
    <svg width="20" height="20" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 .41 13.41z" />
    </svg>
  ),
  0: (
    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 2V0H0V2L8 11V16H3V18H15V16H10V11L18 2ZM4.43 4L2.66 2H15.35L13.57 4H4.43Z" />
    </svg>
  ),
  1: (
    <svg width="18" height="20" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 7H6V0H4V7H2V0H0V7C0 9.12 1.66 10.84 3.75 10.97V20H6.25V10.97C8.34 10.84 10 9.12 10 7V0H8V7ZM13 4V12H15.5V20H18V0C15.24 0 13 2.24 13 4Z" />
    </svg>
  ),
  2: (
    <svg width="16" height="20" viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 4H12C12 1.79 10.21 0 8 0C5.79 0 4 1.79 4 4H2C0.9 4 0 4.9 0 6V18C0 19.1 0.9 20 2 20H14C15.1 20 16 19.1 16 18V6C16 4.9 15.1 4 14 4ZM6 8C6 8.55 5.55 9 5 9C4.45 9 4 8.55 4 8V6H6V8ZM8 2C9.1 2 10 2.9 10 4H6C6 2.9 6.9 2 8 2ZM12 8C12 8.55 11.55 9 11 9C10.45 9 10 8.55 10 8V6H12V8Z" />
    </svg>
  ),
  3: (
    <svg width="22" height="15" viewBox="0 0 22 15" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 8C7.66 8 9 6.66 9 5C9 3.34 7.66 2 6 2C4.34 2 3 3.34 3 5C3 6.66 4.34 8 6 8ZM18 2H10V9H2V0H0V15H2V12H20V15H22V6C22 3.79 20.21 2 18 2Z" />
    </svg>
  ),
  4: (
    <svg width="20" height="18" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 18H2C2.55 18 3 17.55 3 17V8C3 7.45 2.55 7 2 7H0V18ZM19.83 10.88C19.94 10.63 20 10.36 20 10.08V9C20 7.9 19.1 7 18 7H12.5L13.42 2.35C13.47 2.13 13.44 1.89 13.34 1.69C13.11 1.24 12.82 0.83 12.46 0.47L12 0L5.59 6.41C5.21 6.79 5 7.3 5 7.83V15.67C5 16.95 6.05 18 7.34 18H15.45C16.15 18 16.81 17.63 17.17 17.03L19.83 10.88Z" />
    </svg>
  ),
  5: (
    <svg width="20" height="18" viewBox="0 0 20 18" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 0H18C17.45 0 17 0.45 17 1V10C17 10.55 17.45 11 18 11H20V0ZM0.17 7.12C0.0600001 7.37 0 7.64 0 7.92V9C0 10.1 0.9 11 2 11H7.5L6.58 15.65C6.53 15.87 6.56 16.11 6.66 16.31C6.89 16.76 7.18 17.17 7.54 17.53L8 18L14.41 11.59C14.79 11.21 15 10.7 15 10.17V2.34C15 1.05 13.95 0 12.66 0H4.56C3.85 0 3.2 0.37 2.84 0.97L0.17 7.12Z" />
    </svg>
  ),
};

type CategoryProps = {
  icon: keyof typeof mapping;
  className?: string;
  circle?: boolean;
};

export default function Category({
  icon,
  className,
  circle = false,
}: CategoryProps): JSX.Element {
  return (
    <span
      className={[
        css.Container,
        className ?? '',
        circle ? css.Circle : '',
        {
          100: '',
          0: css.Nightlife,
          1: css.Restaurant,
          2: css.Shopping,
          3: css.Hotels,
          4: css.Dos,
          5: css.Donts,
        }[icon],
      ].join(' ')}
    >
      {mapping[icon]}
    </span>
  );
}
