import Icons from 'Components/Icons';
import React from 'react';
import accountIcon from 'Assets/Icons/account.svg';
import { Link } from 'react-router-dom';
import Cards from 'Components/Cards';
import { UserModel } from 'Models/UserModel';
import RouteConfig from 'RouteConfig';
import MiscUtils from 'Utils/MiscUtils';
import Services from 'Services';
import UI from 'Components/UI';
import { useDispatch, useSelector } from 'react-redux';
import AuthSelectors from 'Store/Selectors/AuthSelectors';
import TipListSelectors from 'Store/Selectors/TipListSelectors';
import UserSelectors from 'Store/Selectors/UserSelectors';
import css from './FollowersDialog.module.css';

type FollowersDialogProps = {
  onClose: () => void;
  user?: UserModel;
};

export default function FollowersDialog({
  onClose,
  user,
}: FollowersDialogProps): JSX.Element {
  const dispatch = useDispatch();
  const auth = useSelector(AuthSelectors.get);
  const [view, setView] = React.useState<'users'|'tipLists'>('users');
  const [userIds, setUserIds] = React.useState<string[]>([]);
  const users = useSelector(UserSelectors.list(...userIds));
  const [userCount, setUserCount] = React.useState(0);
  const [selectedUserId, setSelectedUserId] = React.useState<string>();
  const selectedUser = useSelector(UserSelectors.get(selectedUserId ?? ''));
  const [selectedUserTipListIds, setSelectedUserTipListIds] = React.useState<string[]>([]);
  const [followedTipListCount, setFollowedTipListCount] = React.useState<{ [key: string]: number }>(
    {},
  );
  const selectedUserTipLists = useSelector(TipListSelectors.list(...selectedUserTipListIds));
  const tipListsRef = React.useRef<HTMLDivElement>(null);
  const usersRef = React.useRef<HTMLDivElement>(null);
  const userId = user?.id;

  // Load more tip lists function.
  const tipListFuncInitialized = React.useRef(0);
  const loadMoreTipLists = React.useMemo(
    () => {
      const now = new Date().getTime();
      tipListFuncInitialized.current = now;

      if (!(userId && selectedUserId)) {
        return () => {};
      }

      return Services.TipList.getLoadMoreFunc(
        ([nextTiplists, nextTotal]) => {
          if (tipListFuncInitialized.current === now) {
            setSelectedUserTipListIds(nextTiplists.map((tipList) => tipList.id));
            setFollowedTipListCount((current) => ({ ...current, [selectedUserId]: nextTotal }));
          }
        },
        dispatch,
        { limit: 8, filters: { userIds: [userId], followerUserIds: [selectedUserId] } },
      );
    },
    [userId, selectedUserId, dispatch],
  );

  // Load more users function.
  const userFuncInitialized = React.useRef(0);
  const loadMoreUsers = React.useMemo(
    () => {
      const now = new Date().getTime();
      userFuncInitialized.current = now;

      if (!userId) {
        return () => {};
      }

      return Services.User.getLoadMoreFuncFollowersBorrowers(
        async ([nextUsers, nextTotal]) => {
          setUserIds(Object.values(nextUsers).map((value) => value.user.id));
          setUserCount(nextTotal);

          const nextFollowedTipListcount: { [key: string]: number } = {};
          Object.values(nextUsers).forEach((value) => {
            nextFollowedTipListcount[value.user.id] = value.tipListCount ?? 0;
          });

          setFollowedTipListCount((current) => ({ ...current, ...nextFollowedTipListcount }));
        },
        dispatch,
        'followers',
        { limit: 8, userId },
      );
    },
    [userId, dispatch],
  );

  // Set view to tip lists.
  const viewTipLists = React.useCallback((nextUser: UserModel) => {
    setSelectedUserId(nextUser.id);
    setSelectedUserTipListIds([]);
    setView('tipLists');
  }, []);

  // Set view to users.
  const viewUsers = React.useCallback(() => {
    setSelectedUserId(undefined);
    setView('users');
  }, []);

  // Viewing tip lists a user has followed.
  React.useEffect(() => {
    if (view === 'tipLists' && selectedUserId && userId) {
      loadMoreTipLists();
    }

    const ref = tipListsRef.current;

    if (!ref) {
      return () => {};
    }

    // Bind scroll to load more at a certain threshold.
    const onScroll = async () => {
      if (ref.scrollHeight - ref.clientHeight - ref.scrollTop < 200) {
        loadMoreTipLists();
      }
    };

    ref.addEventListener('scroll', onScroll);

    return () => ref.removeEventListener('scroll', onScroll);
  }, [loadMoreTipLists, selectedUserId, userId, view]);

  // Viewing following users.
  React.useEffect(() => {
    if (view === 'users') {
      loadMoreUsers();
    }

    const ref = usersRef.current;

    if (!ref) {
      return () => {};
    }

    // Bind scroll to load more at a certain threshold.
    const onScroll = async () => {
      if (ref.scrollHeight - ref.clientHeight - ref.scrollTop < 200) {
        loadMoreUsers();
      }
    };

    ref.addEventListener('scroll', onScroll);

    return () => ref.removeEventListener('scroll', onScroll);
  }, [view, loadMoreUsers]);

  let ownerLabel = 'your';

  if (user && user.id !== auth?.id) {
    ownerLabel = MiscUtils.formatNameApos(user.firstName);
  }

  return (
    <section className={[css.Container, view === 'tipLists' ? css.TipListView : ''].join(' ')}>
      <div className={css.Header}>
        {view === 'tipLists' && (
          <UI.Button variant="text" onClick={viewUsers} className={css.BackButton}>
            <Icons.BackArrow />
          </UI.Button>
        )}

        <h5>
          {view === 'users' && `Users (${userCount}) who have followed ${ownerLabel} lists`}
          {view === 'tipLists' && `${MiscUtils.capitalize(ownerLabel)} lists (${followedTipListCount[selectedUserId as string] ?? 0}) that are followed by ${selectedUser?.firstName} ${selectedUser?.lastName}`}
        </h5>

        <UI.Button variant="text" onClick={onClose} className={css.CloseButton}>
          <Icons.Close />
        </UI.Button>
      </div>

      {/* Followers view. */}
      {view === 'users' && (
        <div className={css.Content} ref={usersRef}>
          <div className={css.Items}>
            {users.map((nextUser) => (
              <article className={css.User} key={nextUser.id}>
                <Link to={RouteConfig.profile.generate(nextUser.id)} onClick={onClose}>
                  <img
                    src={nextUser.profileImage.thumb ?? accountIcon}
                    alt={nextUser.profileImage.altText}
                    className={css.Image}
                  />
                </Link>

                <p className={css.Info}>
                  <Link
                    to={RouteConfig.profile.generate(nextUser.id)}
                    className={css.Name}
                    onClick={onClose}
                  >
                    {`${nextUser.firstName} ${nextUser.lastName}`}
                  </Link>

                  <UI.Button
                    variant="text"
                    color="secondary"
                    className={css.FollowedCount}
                    onClick={() => viewTipLists(nextUser)}
                  >
                    Followed&nbsp;
                    <span>{MiscUtils.quantify('list', followedTipListCount[nextUser.id] ?? 0)}</span>
                  </UI.Button>

                  <span className={css.Description}>
                    {nextUser.description}
                  </span>
                </p>
              </article>
            ))}
          </div>
        </div>
      )}

      {/* Tip list view. */}
      {view === 'tipLists' && (
        <div className={css.Content} ref={tipListsRef}>
          <div className={css.Items}>
            {selectedUserTipLists.map((tipList) => (
              <Cards.TipList
                key={tipList.id}
                className={css.TipList}
                size="small"
                onNavigate={onClose}
                value={tipList}
              />
            ))}
          </div>
        </div>
      )}
    </section>
  );
}
