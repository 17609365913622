/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserModel } from 'Models/UserModel';

type AuthState = { userId: string | null };
const initialState: AuthState = { userId: null };

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signedIn: (state, action: PayloadAction<UserModel>) => {
      state.userId = action.payload.id;
    },
    authUpdated: (state, action: PayloadAction<UserModel | null>) => {
      state.userId = action.payload?.id ?? null;
    },
    signedOut: (state) => {
      state.userId = null;
    },
  },
});

export const { signedIn, authUpdated, signedOut } = authSlice.actions;
export default authSlice.reducer;
