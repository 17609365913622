/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { TipModel } from 'Models/TipModel';
import { tipBorrowedChanged, tipRemoved, tipUpdated } from './commonActions';
import { userUpdated } from './UserReducer';
import { venueUpdated } from './VenueReducer';

type TipState = { [id: string]: TipModel };
const initialState: TipState = {};

const tipSlice = createSlice({
  name: 'tip',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(tipUpdated, (state, action) => {
      action.payload.forEach((tip) => {
        state[tip.id] = tip;
      });
    });
    builder.addCase(tipRemoved, (state, action) => {
      delete state[action.payload.id];
    });
    builder.addCase(tipBorrowedChanged, (state, action) => {
      state[action.payload.id] = action.payload;
    });
    builder.addCase(venueUpdated, (state, action) => {
      const venues = action.payload;
      const tips = Object.values(state);

      venues.forEach((venue) => {
        const tip = tips.find((nextTip) => nextTip.venue?.id === venue.id);

        if (tip) {
          state[tip.id] = { ...tip, venue };
        }
      });
    });
    builder.addCase(userUpdated, (state, action) => {
      const users = action.payload;
      const tips = Object.values(state);

      users.forEach((user) => {
        const tip = tips.find((nextTip) => nextTip.venue?.id === user.id);

        if (tip) {
          state[tip.id] = { ...tip, user };
        }
      });
    });
  },
});

export default tipSlice.reducer;
